import actionTypes, { RUBRIC, RUBRIC_LIST } from './actionTypes'

export const rubricGet = payload => ({
  type: actionTypes[RUBRIC].pending,
  payload,
})

export const rubricClear = () => ({
  type: actionTypes[RUBRIC].clear,
})

export const rubricList = payload => ({
  type: actionTypes[RUBRIC_LIST].pending,
  payload,
})

export const rubricListClear = () => ({
  type: actionTypes[RUBRIC_LIST].clear,
})
