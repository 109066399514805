import { prop, dissoc } from 'ramda'
import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'
import actionTypes, { CATEGORY, CATEGORY_LIST, CATEGORY_INFO } from './actionTypes'
import * as API from '../../constants/api'
import BarakaApi from '../../helpers/ajax'

const api = new BarakaApi()

const getCategoryEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[CATEGORY].pending),
    mergeMap(({ payload }) =>
      api.get(`${API.CATEGORY_URL}${prop('id', payload)}`, dissoc('id', payload)).pipe(
        map(({ response }) => ({
          type: actionTypes[CATEGORY].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[CATEGORY].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getCategoryListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[CATEGORY_LIST].pending),
    mergeMap(({ payload }) =>
      api.get(API.CATEGORY_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[CATEGORY_LIST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[CATEGORY_LIST].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getCategoryInfoEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[CATEGORY_INFO].pending),
    mergeMap(({ payload }) =>
      api.get(API.CATEGORY_INFO_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[CATEGORY_INFO].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[CATEGORY_INFO].rejected,
            payload: response,
          })
        )
      )
    )
  )

export default combineEpics(getCategoryEpic, getCategoryListEpic, getCategoryInfoEpic)
