import actionTypes, {
  TOKEN,
  TOKEN_CLEAR,
  SIGN_IN,
  SIGN_UP,
  SIGN_UP_PHONE,
  SIGN_UP_REGISTER,
  USER_INFO,
  MAIN_INFO,
} from './actionTypes'

export const tokenSet = payload => ({
  type: TOKEN,
  payload,
})

export const tokenClear = () => ({
  type: TOKEN_CLEAR,
})

export const signIn = payload => ({
  type: actionTypes[SIGN_IN].pending,
  payload,
})

export const signUpPhone = payload => ({
  type: actionTypes[SIGN_UP_PHONE].pending,
  payload,
})

export const signUpPhoneClear = () => ({
  type: actionTypes[SIGN_UP_PHONE].clear,
})

export const signUp = payload => ({
  type: actionTypes[SIGN_UP].pending,
  payload,
})

export const signUpRegister = payload => ({
  type: actionTypes[SIGN_UP_REGISTER].pending,
  payload,
})

export const userInfo = payload => ({
  type: actionTypes[USER_INFO].pending,
  payload,
})

export const userInfoClear = () => ({
  type: actionTypes[USER_INFO].clear,
})

export const mainInfo = payload => ({
  type: actionTypes[MAIN_INFO].pending,
  payload,
})

export const mainInfoClear = () => ({
  type: actionTypes[MAIN_INFO].clear,
})
