import { combineReducers } from 'redux'
import createState from '../../helpers/createState'
import { SOCKET, CHAT_CHECK, CHAT_LIST, CHAT_MESSAGES } from './actionTypes'
import * as STATE from './state'

const reducers = combineReducers({
  [STATE.SOCKET]: createState(SOCKET),
  [STATE.CHAT_CHECK]: createState(CHAT_CHECK),
  [STATE.CHAT_LIST]: createState(CHAT_LIST),
  [STATE.CHAT_MESSAGES]: createState(CHAT_MESSAGES),
})

export default reducers
