export const MAIN = '/'
export const MAIN_STORES = '/shops'

export const AUTH = '/auth'
export const AUTH_SIGNIN = `${AUTH}/signin`
export const AUTH_SIGNIN_PHONE = `${AUTH_SIGNIN}/phone`
export const AUTH_SIGNUP = `${AUTH}/signup`
export const AUTH_SIGNUP_EMAIL = `${AUTH_SIGNUP}/email`

export const USER_ADVERT_LIST = '/user-advert-list'
export const USER_ADVERT_FAVORITE_LIST = '/user-advert-favorite-list'
export const USER_ADVERT_PACKS = '/user-advert-packs'
export const USER_MESSAGE_LIST = '/user-message-list'
export const USER_CHAT_LIST = '/user-chat-list'
export const USER_ORDER_LIST = '/user-order-list'
export const USER_SETTINGS = '/user-settings'
export const USER_HELP = '/user-help'
export const USER_STORE = '/user-store'
export const USER_STORE_LIST = '/user-store-list'
export const USER_STORE_FAVORITE_LIST = '/user-store-favorite-list'
export const USER_REPLENISH = '/user-replenish'

export const ADVERT = '/product'
export const ADVERT_LIST = '/filter'
export const ADVERT_CREATE = '/product-create'
export const ADVERT_SEARCH = '/search'

export const STORE = '/shop'
export const STORE_LIST = '/shop-list'
export const STORE_FILTER_LIST = '/shops/filter'
export const STORE_CREATE = '/shop-create'
export const STORE_SEARCH = '/shop-search'

export const UNAUTHORIZED = '/402'
export const NOT_FOUND = '/404'
export const INTERNAL_SERVER_ERROR = '/505'

export const PAGE = '/page'
export const PAGE_USERPOLICY = `${PAGE}/userpolicy`

export const SITE_MAP = '/sitemap'
