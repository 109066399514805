import { path } from 'ramda'
import { getStore, getStoreForm, getStoreFormExt } from './get'
import * as FORM from '../constants/form'
import * as STATE from '../reducers/state'

const SETTINGS = 'settings'
export const language = path([SETTINGS, STATE.SETTINGS_LANGUAGE])
export const region = path([SETTINGS, STATE.SETTINGS_REGION])
export const city = path([SETTINGS, STATE.SETTINGS_CITY])
export const district = path([SETTINGS, STATE.SETTINGS_DISTRICT])

const AUTH = 'auth'
export const userToken = getStore(STATE.AUTH_TOKEN, AUTH)
export const userInfo = getStore(STATE.AUTH_INFO, AUTH)
export const registrationInfo = getStore(STATE.REGISTRATION_INFO, AUTH)
export const mainInfo = getStore(STATE.MAIN_INFO, AUTH)

const REGIONS = 'regions'
export const regionsCitySearch = getStore(STATE.CITY_SEARCH, REGIONS)
export const regionsCityAllShort = getStore(STATE.CITY_ALL_SHORT, REGIONS)
export const regionsCityRegions = getStore(STATE.CITY_REGIONS, REGIONS)

const RUBRIC = 'rubric'
export const rubricList = getStore(STATE.RUBRIC_LIST, RUBRIC)

const CATEGORY = 'category'
export const category = getStore(STATE.CATEGORY_ITEM, CATEGORY)
export const categoryList = getStore(STATE.CATEGORY_LIST, CATEGORY)
export const categoryInfo = getStore(STATE.CATEGORY_INFO, CATEGORY)

const PAGE_CATEGORY = 'pageCategory'
export const pageCategoryList = getStore(STATE.PAGE_CATEGORY_LIST, PAGE_CATEGORY)

const SUBCATEGORY = 'subcategory'
export const subcategory = getStore(STATE.SUBCATEGORY_ITEM, SUBCATEGORY)

const SITE_MAP = 'siteMap'
export const siteMapInfo = getStore(STATE.WEB_PAGE_SITE_MAP_INFO, SITE_MAP)

const ADVERT = 'advert'
export const advert = getStore(STATE.ADVERT_ITEM, ADVERT)
export const advertEdit = getStore(STATE.ADVERT_EDIT, ADVERT)
export const advertList = getStore(STATE.ADVERT_LIST, ADVERT)
export const advertFilterList = getStore(STATE.ADVERT_FILTER_LIST, ADVERT)
export const advertSuggest = getStore(STATE.ADVERT_SUGGEST, ADVERT)
export const advertSimilarList = getStore(STATE.ADVERT_SIMILAR, ADVERT)

const STORE = 'store'
export const store = getStore(STATE.STORE_ITEM, STORE)
export const storeName = getStore(STATE.STORE_ITEM_NAME, STORE)
export const storeList = getStore(STATE.STORE_LIST, STORE)
export const storeListWeb = getStore(STATE.STORE_WEB_LIST, STORE)
export const storeFilterList = getStore(STATE.STORE_FILTER_LIST, STORE)

const USER = 'user'
export const userProductListActive = getStore(STATE.USER_PRODUCT_LIST_ACTIVE, USER)
export const userProductListCount = getStore(STATE.USER_PRODUCT_LIST_COUNT, USER)
export const userProductListDeleted = getStore(STATE.USER_PRODUCT_LIST_DELETED, USER)
export const userStoreListActive = getStore(STATE.USER_STORE_LIST_ACTIVE, USER)
export const userStoreListCount = getStore(STATE.USER_STORE_LIST_COUNT, USER)
export const userStoreListDeleted = getStore(STATE.USER_STORE_LIST_DELETED, USER)
export const userStoreNameCheck = getStore(STATE.USER_STORE_NAME_CHECK, USER)
export const userMessageListActive = getStore(STATE.USER_MESSAGE_LIST_ACTIVE, USER)
export const userMessageListCount = getStore(STATE.USER_MESSAGE_LIST_COUNT, USER)
export const userMessageListDeleted = getStore(STATE.USER_MESSAGE_LIST_DELETED, USER)
export const userPromotionProductInfo = getStore(STATE.USER_PROMOTION_PRODUCT_INFO, USER)
export const userPromotionStoreInfo = getStore(STATE.USER_PROMOTION_STORE_INFO, USER)
export const userAdvertFavoriteList = getStore(STATE.USER_ADVERT_FAVORITE_LIST, USER)
export const userStoreFavoriteList = getStore(STATE.USER_STORE_FAVORITE_LIST, USER)
export const userReviewList = getStore(STATE.USER_REVIEW_LIST, USER)

const SOCKET = 'socket'
export const socket = getStore(STATE.SOCKET, SOCKET)
export const chatCheck = getStore(STATE.CHAT_CHECK, SOCKET)
export const chatList = getStore(STATE.CHAT_LIST, SOCKET)
export const chatMessages = getStore(STATE.CHAT_MESSAGES, SOCKET)

// const FORM = 'form'
export const formValuesSignInEmail = getStoreForm(FORM.AUTH_SIGNIN_EMAIL_FORM)
export const formValuesAuthSignUp = getStoreForm(FORM.AUTH_SIGNUP_FORM)
export const formValuesSearch = getStoreForm(FORM.SEARCH_FORM)
export const formValuesFilter = getStoreForm(FORM.FILTER_FORM)
export const formValuesOptions = getStoreForm(FORM.OPTIONS_FORM)
export const formValuesAdvertCreate = getStoreForm(FORM.ADVERT_CREATE_FORM)
export const formValuesAdvertMessageCreate = getStoreForm(FORM.ADVERT_MESSAGE_CREATE_FORM)
export const formValuesAdvertComplainCreate = getStoreForm(FORM.ADVERT_COMPLAIN_CREATE_FORM)
export const formValuesStoreCreate = getStoreForm(FORM.STORE_CREATE_FORM)
export const formValuesStoreCreateExt = getStoreFormExt(FORM.STORE_CREATE_FORM)
export const formValuesStoreCreateWorkingHours = getStoreForm(FORM.STORE_CREATE_WORKING_HOURS_FORM)
export const formValuesStoreUserReview = getStoreForm(FORM.STORE_USER_REVIEW_FORM)
export const formValuesUserProfileUpdate = getStoreForm(FORM.USER_PROFILE_UPDATE_FORM)

const ROUTER = 'router'
export const router = state => ({
  location: path([ROUTER, 'location'], state),
  pathname: path([ROUTER, 'location', 'pathname'], state),
  query: path([ROUTER, 'location', 'query'], state),
  state: path([ROUTER, 'location', 'state'], state),
})
