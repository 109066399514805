import ruMessages from '../locales/ru-RU'

const RuLang = {
  messages: {
    ...ruMessages,
  },
  locale: 'ru-Ru',
}

export default RuLang
