import { TOKEN } from '../constants/storage'
import { isBrowser } from '../helpers/is'

const isLocalStorage = isBrowser && localStorage
const isSessionStorage = isBrowser && sessionStorage

export const getToken = () =>
  (isLocalStorage && isLocalStorage.getItem(TOKEN)) ||
  (isSessionStorage && isSessionStorage.getItem(TOKEN))

export const setToken = (token, local = false) => {
  if (local) {
    return isSessionStorage && sessionStorage.setItem(TOKEN, token)
  }

  return isLocalStorage && localStorage.setItem(TOKEN, token)
}

export const removeToken = () => {
  isLocalStorage && isLocalStorage.removeItem(TOKEN)
  isSessionStorage && isSessionStorage.removeItem(TOKEN)
}
