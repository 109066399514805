import { combineReducers } from 'redux'
import createState from '../../helpers/createState'
import { CATEGORY, CATEGORY_LIST, CATEGORY_INFO } from './actionTypes'
import * as STATE from './state'

const reducer = combineReducers({
  [STATE.CATEGORY_ITEM]: createState(CATEGORY),
  [STATE.CATEGORY_LIST]: createState(CATEGORY_LIST),
  [STATE.CATEGORY_INFO]: createState(CATEGORY_INFO),
})

export default reducer
