import createAction from '../../helpers/createAction'

export const SOCKET = 'SOCKET'
export const SOCKET_APP_SEND_MESSAGE = 'SOCKET_APP_SEND_MESSAGE'
export const SOCKET_USER_QUEUE_MESSAGES = 'SOCKET_USER_QUEUE_MESSAGES'
export const CHAT_CHECK = 'CHAT_CHECK'
export const CHAT_CREATE = 'CHAT_CREATE'
export const CHAT_LIST = 'CHAT_LIST'
export const CHAT_MESSAGES = 'CHAT_MESSAGES'

export default {
  [SOCKET]: createAction(SOCKET),
  [SOCKET_APP_SEND_MESSAGE]: createAction(SOCKET_APP_SEND_MESSAGE),
  [SOCKET_USER_QUEUE_MESSAGES]: createAction(SOCKET_USER_QUEUE_MESSAGES),
  [CHAT_CHECK]: createAction(CHAT_CHECK),
  [CHAT_CREATE]: createAction(CHAT_CREATE),
  [CHAT_LIST]: createAction(CHAT_LIST),
  [CHAT_MESSAGES]: createAction(CHAT_MESSAGES),
}
