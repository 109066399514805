import { prop, dissoc } from 'ramda'
import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'
import actionTypes, { RUBRIC, RUBRIC_LIST } from './actionTypes'
import * as API from '../../constants/api'
import BarakaApi from '../../helpers/ajax'
import { getToken } from '../../helpers/tokenStorage'

const api = new BarakaApi()
const token = getToken()

const getRubricEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[RUBRIC].pending),
    mergeMap(({ payload }) =>
      api.get(`${API.RUBRIC_URL}${prop('id', payload)}`, dissoc('id', payload)).pipe(
        map(({ response }) => ({
          type: actionTypes[RUBRIC].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[RUBRIC].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getRubricListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[RUBRIC_LIST].pending),
    mergeMap(({ payload }) => {
      const secureApi = token ? api.securedGet : api.get

      return secureApi(API.RUBRIC_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[RUBRIC_LIST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[RUBRIC_LIST].rejected,
            payload: response,
          })
        )
      )
    })
  )

export default combineEpics(getRubricEpic, getRubricListEpic)
