import { prop } from 'ramda'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import Routes from './routes'
import * as actions from './reducers/actions'
import { getToken } from './helpers/tokenStorage'
import { getRegion } from './helpers/regionStorage'
import { getCity } from './helpers/cityStorage'
import { getDistrict } from './helpers/districtStorage'
import * as getStore from './helpers/getStore'
// import { isBrowser } from './helpers/is'

function RouterProvider({ history }) {
  const dispatch = useDispatch()

  const { data: token } = useSelector(getStore.userToken)
  const { data: userInfo } = useSelector(getStore.userInfo)
  const refreshToken = prop('refresh_token', userInfo)
  const storageToken = getToken()
  const region = getRegion()
  const city = getCity()
  const district = getDistrict()

  // console.log('refresh', Boolean(refreshToken))

  // useEffect(() => {
  //   if (refreshToken) {
  //     removeToken()
  //     setToken(refreshToken)
  //     isBrowser && window.location.reload()
  //   }
  // }, [userInfo])

  useEffect(() => {
    dispatch(
      actions.userInfo({
        secure: token || storageToken,
      })
    )

    // if (refreshToken) {
    //   removeToken()
    //   setToken(refreshToken)
    //   isBrowser && window.location.reload()
    // }

    if (!refreshToken) {
      dispatch(
        actions.mainInfo({
          secure: token || storageToken,
        })
      )

      dispatch(actions.pageCategoryList())
      dispatch(actions.cityRegions())

      dispatch(actions.settingsRegion(region))
      dispatch(actions.settingsCity(city))
      dispatch(actions.settingDistrict(district))
    }
  }, [dispatch, token, storageToken, region, city, district])

  return (
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  )
}

export default RouterProvider
