export default {
  'general.copyright': 'baraka © 2020 Все права защищены.',
  'general.location': 'местоположение',
  'general.district': 'Район',
  'general.city': 'Город',
  'general.byCBUCurse': 'по курсу ЦБ РУз',

  'modal-confirm': 'Вы уверены?',

  enterToBaraka: 'Войти в Baraka',
  signupToBaraka: 'Расскажите немного о себе',
  'signup-description': 'Заполните краткую информацию о себе, чтобы завершить регистрацию',
  'auth-signup-button': 'Завершить регистрацию',
  toCreateProductLoginInSite: 'Чтобы войти воспользуйтесь одним из следующих способов',
  signUpSubtitle: 'Чтобы создать аккаунт воспользуйтесь одним из следующих способов:',
  signUpEmailSubtitle: 'Введите свой e-mail и придумайте новый пароль',
  loginViaPhone: 'Войти с помощью телефона',
  loginViaEmail: 'Войти с помощью e-mail',
  signUpViaPhone: 'Регистрация с помощью телефона',
  signUpViaEmail: 'С помощью e-mail',
  password: 'Пароль',
  enterToSystem: 'Войти в систему',
  signUp: 'Зарегистрироваться',
  enterYourPhoneNumber: 'Введите номер телефона',
  smsCode: 'Введите смс-код',

  home: 'главная',
  adverts: 'объявления',
  shops: 'мой сайт',
  messages: 'сообщение',
  'choose-a-place': 'выбрать место',
  'post-add-title': 'Добавить объявление на Baraka',
  search: 'поиск',
  'search-placeholder': 'Что вы ищете...',
  'searching-results': 'результаты поиска',
  'my-adverts': 'мои объявления',
  'my-messages': 'мои сообщения',

  editProduct: 'Редактировать объявление',
  'store-edit': 'Редактировать магазин',
  'store-archive': 'Отключить магазин',
  'store-unarchive': 'Включить магазин',
  productDeclined: 'Объявление отклонено',
  productBlocked: 'Объявление заблокировано',
  archivateProduct: 'Архивировать объявление',
  activateProduct: 'Активировать объявление',
  location: 'Местоположение',
  description: 'Описание',
  inFavorites: 'В избранном',
  views: 'Просмотров',
  share: 'Поделиться',
  productCreatedAt: 'Размещено',
  similarProducts: 'Похожие объявления',
  showNumber: 'Показать номер',
  writeToSeller: 'Написать продавцу',
  complainToProduct: 'Пожаловаться на объявление',
  promotionProduct: 'Продвинуть объявление',
  additionalInfo: 'Дополнительная информация',
  promotionProductOnTopWeek: 'На продвижении  - в ТОПе на 7 дней до ',
  promotionProductOnTop30Days: 'На продвижении - в ТОПе на 30 дня до ',

  contacts: 'Контакты',
  products: 'Объявления',
  feedbacks: 'Отзывы',
  aboutCompany: 'О Компании',
  allStores: 'Все магазины',
  more: 'Подробнее',
  leaveFeedback: 'Оставить отзыв',
  newFeedback: 'Новый отзыв',
  changeFeedback: 'Изменить отзыв',
  deleteFeedBack: 'Удалить отзыв',
  writeToOwner: 'Написать владельцу',
  'store-modal-all-days': 'Все дни',
  'working-hour-bnt-all-days': 'Для всех',
  'working-hour-start-time': 'Начало',
  'working-hour-end-time': 'Конец',
  'working-hour-monday': 'Понедельник',
  'working-hour-tuesday': 'Вторник',
  'working-hour-wednesday': 'Среда',
  'working-hour-thursday': 'Четверг',
  'working-hour-friday': 'Пятница',
  'working-hour-saturday': 'Суббота',
  'working-hour-sunday': 'Воскресенье',
  'working-hour-day-off': 'Выходной',
  'working-hour-modal-title': 'Выберите рабочие часы',
  'select-category-modal-title': 'Выберите категорию',

  'your-id': 'Ваш ID',
  'your-balance': 'Ваш баланс',
  'fill-balance-description':
    'Пополните Ваш счет, чтобы продвигать свои объявления на Baraka и открыть свой магазин',
  replenishBalance: 'Пополнить баланс',
  changeInformation: 'Изменить информацию',

  chooseRegion: 'Выберите регион',
  'create-product': 'Разместить объявление ',
  'navbar.products': 'Объявления',
  'navbar.stores': 'Магазины',
  'navbar.messages': 'Сообщения',
  'navbar.favorites': 'Избранное',

  'choose-category': 'Выберите категорию',
  allProducts: 'Все объявления',
  'your-adverts': 'ваши объявления',
  showMore: 'Показать все ',
  showLess: 'Скрыть ',

  chooseSorting: 'Выберите сортировку',
  chooseRubric: 'Выберите рубрику',
  chooseCategory: 'Выберите категорию',
  chooseSubCategory: 'Выберите подкатегорию',
  priceSum: 'Цена, сум',
  applyFilters: 'Применить',
  resetFilters: 'Сбросить фильтры ',

  sortByDate: 'Сортировать по дате',
  sortByPrice: 'Сортировать по цене',

  sortAsc: 'По возрастанию',
  sortDesc: 'По убыванию',

  'modal.rubric.title': 'Выберите рубрику',
  rubric: 'Рубрика',
  category: 'Категория',
  subcategory: 'Подкатегория',
  knowMore: 'Узнайте больше',

  'general-products': 'Обычные объявления',
  'top-products': 'Топ-объявления',
  'with-translation': 'с переводом',
  'store-username-not-available': 'это название уже занято',
  'store-username': 'ссылка магазина (только латиница)',
  'store-name': 'Название магазина',
  'store-description': 'Описание магазина',
  'store-address': 'адрес магазина',
  'store-telegram': 'Telegram',
  'store-instagram': 'Instagram',
  'store-facebook': 'Facebook',
  'store-youtube': 'Youtube',
  'store-phones-title': 'номера телефонов',
  'store-working-hours': 'рабочие часы',
  'store-category': 'категории магазина',
  'store-brand-image': 'логотип магазина - 400x400',
  'store-top-image': 'верхнее изображение (сайт) - 1920x420',
  'store-mobile-top-image': 'верхнее изображение (мобильное) - 500x300',
  'store-left-image': 'левое изображение (сайт) - 300x1500',
  'store-right-image': 'правое изображение (сайт) - 300x1500',
  'create-store-title': 'создать магазин',

  'advert-need-choose-district': 'выберите район',
  'advert-need-choose-city': 'выберите город',
  'advert-need-choose-rubric': 'выберите рубрику',
  complain: 'Отправить жалобу',
  'complain-options': 'Выберите вариант',
  'complain-wrong-category': 'Неправильная категория',
  'complain-forbidden-product': 'Запрещенный товар',
  'complain-adult-content': 'Непристойное содержание',
  'complain-fraud': 'Мошенничество',
  'complain-spam': 'спам',
  'complain-other': 'Другое',
  'advert-promotion-your-id': 'ваш ID',
  'advert-promotion-your-balance': 'ваш баланс',
  'advert-promotion-replenish-account': 'пополнить счет',
  'advert-promotion-connect-service': 'подключить услугу',
  'advert-promotion-price': 'стоимость',
  'advert-promotion-payment-method': 'выберите способ оплаты',
  'advert-promotion-pay-via-payme': 'Оплатить через Payme',
  'advert-promotion-pay-via-click': 'Оплатить через Click',
  'advert-promotion-pay-via-paynet': 'Оплатить через Paynet',
  'advert-promotion-paynet-hint':
    'Найдите ближайший оператор Paynet и продиктуйте ему свой ID и сумму на которую хотите пополнить свой баланс',

  create: 'создать',
  change: 'изменить',
  add: 'добавить',
  remove: 'убрать',
  cancel: 'отмена',
  'change-info': 'изменить информация',
  'replenish-account': 'пополнить счет',
  'come-back': 'вернуться назад',
  or: 'или',
  back: 'назад',
  showAll: 'Показать все',
  select: 'Выбрать',
  selected: 'Выбран',
  chooseStore: 'Выберите магазин',
  delete: 'Удалить',

  myProducts: 'Мои объявления',
  productTarrifs: 'Тарифы',
  myMessages: 'Мои сообщения',
  createStore: 'Создать магазин',
  settings: 'Настройки',
  help: 'Помощь',
  logout: 'Выйти',
  login: 'Войти',
  myFavorites: 'Избранное',

  'in-russian': 'на русском',
  'in-english': 'на английском',
  'in-uzbek': 'на узбекском',
  'russian-short': 'русс',
  'english-short': 'англ',
  'uzbek-short': 'узб',
  english: 'английский',
  russian: 'русский',
  uzbek: 'узбекский',

  city: 'город',
  cities: 'города',
  chooseOrSearchCity: 'введите название города в поиске или выберите из списка популярных',
  advertPromotionDescription:
    'Пополните Ваш счет, чтобы продвигать свои объявления на Baraka и открыть свой магазин',

  myStores: 'Мои магазины',
  'store-deleted': 'Не активные',
  'store-active': 'Активные',
  'advert-deleted': 'Не активные',
  'advert-active': 'Активные',
  'your-stores': 'Ваши магазины',

  'no-results-title': 'Ничего не найдено',
  'no-results-subtitle':
    'По вашему запросу ничего не найдено. Измените параметры поиска и попробуйте снова',

  'favorite-list': 'Избранное',
  save: 'Сохранить',
  smsVerificationError: 'Неправельный СМС код',
}
