import actionTypes, { CITY_SEARCH, CITY_ALL_SHORT, CITY_REGIONS } from './actionTypes'

export const citySearch = payload => ({
  type: actionTypes[CITY_SEARCH].pending,
  payload,
})

export const citySearchClear = () => ({
  type: actionTypes[CITY_SEARCH].clear,
})

export const cityAllShort = payload => ({
  type: actionTypes[CITY_ALL_SHORT].pending,
  payload,
})

export const cityAllShortClear = () => ({
  type: actionTypes[CITY_ALL_SHORT].clear,
})

export const cityRegions = payload => ({
  type: actionTypes[CITY_REGIONS].pending,
  payload,
})

export const cityRegionsClear = () => ({
  type: actionTypes[CITY_REGIONS].clear,
})
