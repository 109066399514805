import { prop, dissoc } from 'ramda'
import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError, mapTo } from 'rxjs/operators'
import { push } from 'connected-react-router'
import actionTypes, {
  ADVERT,
  ADVERT_CREATE,
  ADVERT_EDIT,
  ADVERT_DELETE,
  ADVERT_ACTIVATE,
  ADVERT_LIST,
  ADVERT_FILTER_LIST,
  ADVERT_SUGGEST,
  ADVERT_SIMILAR,
  ADVERT_USER,
  ADVERT_PHONE,
  ADVERT_MESSAGE_CREATE,
  ADVERT_COMPLAIN_CREATE,
  ADVERT_COMPLAIN_LIST,
} from './actionTypes'
import * as API from '../../constants/api'
import * as ROUTE from '../../constants/routes'
import BarakaApi from '../../helpers/ajax'
import { toastrError, toastrSuccess } from '../../helpers/toastr'
import { getToken } from '../../helpers/tokenStorage'

const api = new BarakaApi()

const getAdvertEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT].pending),
    mergeMap(({ payload }) =>
      api.securedGet(`${API.ADVERT_MAIN_URL}${prop('id', payload)}`).pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT].rejected,
            payload: response,
          })
        )
      )
    )
  )

const createAdvertEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_CREATE].pending),
    mergeMap(({ payload }) =>
      api.securedPost(API.ADVERT_MAIN_CREATE_URL, null, dissoc('store_name', payload)).pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT_CREATE].fulfilled,
          payload: response,
          args: payload,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT_CREATE].rejected,
            payload: response,
            args: payload,
          })
        )
      )
    )
  )

const createAdvertFulfilledNotificationEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_CREATE].fulfilled),
    map(() =>
      toastrSuccess({
        id: Math.floor(Math.random() * 1000),
        title: 'Объявлено добавлено',
        message: 'Объявление успещно создано и будет доступно после проверки модераторами сайта',
      })
    )
  )

const createAdvertRejectedNotificationEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_CREATE].rejected),
    map(() =>
      toastrError({
        id: Math.floor(Math.random() * 1000),
        title: 'Объявлено не добавлено',
        message: 'При добавлении объявлении возникла ошибка. Попробуйте снова',
      })
    )
  )

const createAdvertFulfilledRouteEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_CREATE].fulfilled),
    map(({ args }) => {
      const storeName = prop('store_name', args)

      if (storeName) {
        return push(`${ROUTE.STORE}/${storeName}/`)
      } else {
        return push(ROUTE.MAIN)
      }
    })
  )

const editAdvertEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_EDIT].pending),
    mergeMap(({ payload }) =>
      api.securedPost(API.ADVERT_MAIN_EDIT_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT_EDIT].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT_EDIT].rejected,
            payload: response,
          })
        )
      )
    )
  )

const deleteAdvertEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_DELETE].pending),
    mergeMap(({ payload }) =>
      api.securedDelete(`${API.ADVERT_MAIN_DELETE_URL}${prop('id', payload)}`).pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT_DELETE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT_DELETE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const deleteAdvertFulfilledEpic = action$ =>
  action$.pipe(ofType(actionTypes[ADVERT_DELETE].fulfilled), mapTo(push(ROUTE.USER_ADVERT_LIST)))

const activateAdvertEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_ACTIVATE].pending),
    mergeMap(({ payload }) =>
      api.securedPut(`${API.ADVERT_MAIN_ACTIVATE_URL}${prop('id', payload)}`).pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT_ACTIVATE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT_ACTIVATE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const activateAdvertFulfilledEpic = action$ =>
  action$.pipe(ofType(actionTypes[ADVERT_ACTIVATE].fulfilled), mapTo(push(ROUTE.USER_ADVERT_LIST)))

const getAdvertListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_LIST].pending),
    mergeMap(({ payload }) =>
      api.get(API.ADVERT_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT_LIST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT_LIST].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getAdvertFilterListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_FILTER_LIST].pending),
    mergeMap(({ payload }) => {
      const request =
        (getToken() && api.securedPost(API.ADVERT_MAIN_FILTER_ALL_URL, payload, payload)) ||
        api.post(API.ADVERT_MAIN_FILTER_ALL_URL, payload, payload)
      return request.pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT_FILTER_LIST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT_FILTER_LIST].rejected,
            payload: response,
          })
        )
      )
    })
  )

const getAdvertSuggestEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_SUGGEST].pending),
    mergeMap(({ payload }) =>
      api.get(API.ADVERT_MAIN_FULL_SUGGEST_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT_SUGGEST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT_SUGGEST].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getAdvertSimilarEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_SIMILAR].pending),
    mergeMap(({ payload }) => {
      const request =
        (getToken() &&
          api.securedGet(
            `${API.ADVERT_MAIN_SIMILAR_URL}${prop('id', payload)}`,
            dissoc('id', payload)
          )) ||
        api.get(`${API.ADVERT_MAIN_SIMILAR_URL}${prop('id', payload)}`, dissoc('id', payload))
      return request.pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT_SIMILAR].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT_SIMILAR].rejected,
            payload: response,
          })
        )
      )
    })
  )

const getAdvertUserEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_USER].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.ADVERT_MAIN_USER_URL).pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT_USER].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT_USER].rejected,
            payload: response,
          })
        )
      )
    )
  )

const postAdvertPhoneEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_PHONE].pending),
    mergeMap(({ payload }) =>
      api.post(API.COUNTER_CLICK_PHONE_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT_PHONE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT_PHONE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const postAdvertMessageCreateEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_MESSAGE_CREATE].pending),
    mergeMap(({ payload }) =>
      api.post(API.PRODUCT_MESSAGE_CREATE_URL, null, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT_MESSAGE_CREATE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT_MESSAGE_CREATE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const postAdvertComplainCreateEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_COMPLAIN_CREATE].pending),
    mergeMap(({ payload }) =>
      api.securedPost(API.ADVERT_COMPLAIN_URL, null, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT_COMPLAIN_CREATE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT_COMPLAIN_CREATE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getAdvertComplainListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ADVERT_COMPLAIN_LIST].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.ADVERT_COMPLAIN_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[ADVERT_COMPLAIN_LIST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ADVERT_COMPLAIN_LIST].rejected,
            payload: response,
          })
        )
      )
    )
  )

export default combineEpics(
  getAdvertEpic,
  createAdvertEpic,
  createAdvertFulfilledNotificationEpic,
  createAdvertRejectedNotificationEpic,
  createAdvertFulfilledRouteEpic,
  // createAdvertRejectedRouteEpic,
  editAdvertEpic,
  deleteAdvertEpic,
  deleteAdvertFulfilledEpic,
  activateAdvertEpic,
  activateAdvertFulfilledEpic,
  getAdvertListEpic,
  getAdvertFilterListEpic,
  getAdvertSuggestEpic,
  getAdvertSimilarEpic,
  getAdvertUserEpic,
  postAdvertPhoneEpic,
  postAdvertMessageCreateEpic,
  postAdvertComplainCreateEpic,
  getAdvertComplainListEpic
)
