import React from 'react'
import { useSelector } from 'react-redux'
import { IntlProvider as Provider } from 'react-intl'
import AppLocale from './language'
import * as getStore from './helpers/getStore'

function IntlProvider({ children }) {
  const language = useSelector(getStore.language)
  const currentLocale = AppLocale[language]

  return (
    <Provider locale={currentLocale.locale} messages={currentLocale.messages}>
      {children}
    </Provider>
  )
}

export default IntlProvider
