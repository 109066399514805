import { split, tail, replace, equals, head, find } from 'ramda'
import { MAIN_STORES, STORE, STORE_LIST, STORE_CREATE, STORE_SEARCH } from '../constants/routes'

export const isBrowser = typeof window === 'object'

export const isStore = pathname => {
  const pathSplit = split('/')
  const routes = tail(pathSplit(pathname))
  const firstRoute = head(routes)
  const slashReplacer = replace('/', '')
  const mainStore = slashReplacer(MAIN_STORES)
  const store = slashReplacer(STORE)
  const storeList = slashReplacer(STORE_LIST)
  const storeCreate = slashReplacer(STORE_CREATE)
  const storeSearch = slashReplacer(STORE_SEARCH)
  const stores = [mainStore, store, storeList, storeCreate, storeSearch]
  const isEquals = find(item => equals(firstRoute, item), stores)

  return Boolean(isEquals)
}
