import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import importedComponent, { LazyBoundary } from 'react-imported-component'
import { assignImportedComponents } from 'react-imported-component/macro'
import * as ROUTE from '../constants/routes'

export const applicationImports = assignImportedComponents([
  [() => import('../containers/mainContainer'), '', '../containers/mainContainer', false],
])

const LazyLoader = ({ children }) => (
  <LazyBoundary
    fallback={
      <div className='loader'>
        <div className='loader_ajax_small' />
      </div>
    }
  >
    {children}
  </LazyBoundary>
)

const notFound = importedComponent(() =>
  import(/* webpackChunkName: "not_found" */ '../containers/notFoundContainer')
)
const AuthContainer = importedComponent(
  () => import(/* webpackChunkName: "auth_container" */ '../containers/authContainer'),
  { async: true }
)
const AuthSignInEmailContainer = importedComponent(
  () =>
    import(
      /* webpackChunkName: "auth_signin_email_container" */ '../containers/authSignInEmailContainer'
    ),
  { async: true }
)
const AuthSignInPhoneContainer = importedComponent(
  () =>
    import(
      /* webpackChunkName: "auth_signin_phone_container" */ '../containers/authSignInPhoneContainer'
    ),
  { async: true }
)
const AuthSignUpContainer = importedComponent(
  () => import(/* webpackChunkName: "auth_signup_container" */ '../containers/authSignUpContainer'),
  { async: true }
)
const MainContainer = importedComponent(
  () => import(/* webpackChunkName: "main_container" */ '../containers/mainContainer'),
  { async: true }
)
const MainStoresContainer = importedComponent(
  () => import(/* webpackChunkName: "main_shops_container" */ '../containers/mainStoresContainer'),
  { async: true }
)
const PageContainer = importedComponent(
  () => import(/* webpackChunkName: "page_container" */ '../containers/pageContainer'),
  { async: true }
)
const AdvertContainer = importedComponent(
  () => import(/* webpackChunkName: "product_container" */ '../containers/advertContainer'),
  { async: true }
)
const AdvertListContainer = importedComponent(
  () =>
    import(/* webpackChunkName: "product_list_container" */ '../containers/advertListContainer'),
  { async: true }
)
const AdvertCreateContainer = importedComponent(
  () =>
    import(
      /* webpackChunkName: "product_create_container" */ '../containers/advertCreateContainer'
    ),
  { async: true }
)
const AdvertSearchContainer = importedComponent(
  () =>
    import(
      /* webpackChunkName: "product_search_container" */ '../containers/advertSearchContainer'
    ),
  { async: true }
)
const AdvertLabelContainer = importedComponent(
  () =>
    import(/* webpackChunkName: "product_label_container" */ '../containers/advertLabelContainer'),
  { async: true }
)
const StoreContainer = importedComponent(
  () => import(/* webpackChunkName: "shop_container" */ '../containers/storeContainer'),
  { async: true }
)
const StoreListContainer = importedComponent(
  () => import(/* webpackChunkName: "shop_list_container" */ '../containers/storeListContainer'),
  { async: true }
)
const StoreFilterListContainer = importedComponent(
  () =>
    import(
      /* webpackChunkName: "shop_filter_list_container" */ '../containers/storeFilterListContainer'
    ),
  { async: true }
)
const StoreCreateContainer = importedComponent(
  () =>
    import(/* webpackChunkName: "store_create_container" */ '../containers/storeCreateContainer'),
  { async: true }
)
const StoreSearchContainer = importedComponent(
  () =>
    import(/* webpackChunkName: "shop_search_container" */ '../containers/storeSearchContainer'),
  { async: true }
)
const StoreLabelContainer = importedComponent(
  () => import(/* webpackChunkName: "shop_label_container" */ '../containers/storeLabelContainer'),
  { async: true }
)
const UserAdvertListContainer = importedComponent(
  () =>
    import(
      /* webpackChunkName: "user_product_list_container" */ '../containers/userAdvertListContainer'
    ),
  { async: true }
)
const UserAdvertFavoriteListContainer = importedComponent(
  () =>
    import(
      /* webpackChunkName: "user_product_favorite_list_container" */ '../containers/userAdvertFavoriteList'
    ),
  { async: true }
)
const UserAdvertPacksContainer = importedComponent(
  () =>
    import(
      /* webpackChunkName: "user_product_packs_container" */ '../containers/userAdvertPacksContainer'
    ),
  { async: true }
)
const UserMessageListContainer = importedComponent(
  () =>
    import(
      /* webpackChunkName: "user_message_list_container" */ '../containers/userMessageListContainer'
    ),
  { async: true }
)
const UserChatListContainer = importedComponent(
  () =>
    import(
      /* webpackChunkName: "user_chat_list_container" */ '../containers/userChatListContainer'
    ),
  { async: true }
)
const UserOrderListContainer = importedComponent(
  () =>
    import(
      /* webpackChunkName: "user_order_list_container" */ '../containers/userOrderListContainer'
    ),
  { async: true }
)
const UserSettingsContainer = importedComponent(
  () =>
    import(/* webpackChunkName: "user_settings_container" */ '../containers/userSettingsContainer'),
  { async: true }
)
const UserHelpContainer = importedComponent(
  () => import(/* webpackChunkName: "user_help_container" */ '../containers/userHelpContainer'),
  { async: true }
)
const UserStoreContainer = importedComponent(
  () => import(/* webpackChunkName: "user_store_container" */ '../containers/userStoreContainer'),
  { async: true }
)
const UserStoreListContainer = importedComponent(
  () =>
    import(
      /* webpackChunkName: "user_shop_list_container" */ '../containers/userStoreListContainer'
    ),
  { async: true }
)
const UserReplenishContainer = importedComponent(
  () =>
    import(
      /* webpackChunkName: "user_replenish_container" */ '../containers/userReplenishContainer'
    ),
  { async: true }
)
const SiteMapContainer = importedComponent(() => import('../containers/siteMapContainer'), {
  async: true,
})

const exampleMain = importedComponent(() =>
  import(/* webpackChunkName: "example_main_container" */ '../containers/exampleMainContainer')
)
const exampleFullWidth = importedComponent(() =>
  import(
    /* webpackChunkName: "example_full_width_container" */ '../containers/exampleFullWidthContainer'
  )
)
const exampleText = importedComponent(() =>
  import(/* webpackChunkName: "example_text_container" */ '../containers/exampleTextContainer')
)

const Auth = () => (
  <LazyLoader>
    <AuthContainer />
  </LazyLoader>
)
const AuthSignInEmail = () => (
  <LazyLoader>
    <AuthSignInEmailContainer />
  </LazyLoader>
)
const AuthSignInPhone = () => (
  <LazyLoader>
    <AuthSignInPhoneContainer />
  </LazyLoader>
)
const AuthSignUp = () => (
  <LazyLoader>
    <AuthSignUpContainer />
  </LazyLoader>
)
const Main = () => (
  <LazyLoader>
    <MainContainer />
  </LazyLoader>
)
const MainStores = () => (
  <LazyLoader>
    <MainStoresContainer />
  </LazyLoader>
)
const Page = () => (
  <LazyLoader>
    <PageContainer />
  </LazyLoader>
)
const Advert = () => (
  <LazyLoader>
    <AdvertContainer />
  </LazyLoader>
)
const AdvertList = () => (
  <LazyLoader>
    <AdvertListContainer />
  </LazyLoader>
)
const AdvertCreate = () => (
  <LazyLoader>
    <AdvertCreateContainer />
  </LazyLoader>
)
const AdvertSearch = () => (
  <LazyLoader>
    <AdvertSearchContainer />
  </LazyLoader>
)
const AdvertLabel = () => (
  <LazyLoader>
    <AdvertLabelContainer />
  </LazyLoader>
)
const Store = () => (
  <LazyLoader>
    <StoreContainer />
  </LazyLoader>
)
const StoreList = () => (
  <LazyLoader>
    <StoreListContainer />
  </LazyLoader>
)
const StoreFilterList = () => (
  <LazyLoader>
    <StoreFilterListContainer />
  </LazyLoader>
)
const StoreCreate = () => (
  <LazyLoader>
    <StoreCreateContainer />
  </LazyLoader>
)
const StoreSearch = () => (
  <LazyLoader>
    <StoreSearchContainer />
  </LazyLoader>
)
const StoreLabel = () => (
  <LazyLoader>
    <StoreLabelContainer />
  </LazyLoader>
)
const UserAdvertList = () => (
  <LazyLoader>
    <UserAdvertListContainer />
  </LazyLoader>
)
const UserAdvertFavoriteList = () => (
  <LazyLoader>
    <UserAdvertFavoriteListContainer />
  </LazyLoader>
)
const UserAdvertPacks = () => (
  <LazyLoader>
    <UserAdvertPacksContainer />
  </LazyLoader>
)
const UserMessageList = () => (
  <LazyLoader>
    <UserMessageListContainer />
  </LazyLoader>
)
const UserChatList = () => (
  <LazyLoader>
    <UserChatListContainer />
  </LazyLoader>
)
const UserOrderList = () => (
  <LazyLoader>
    <UserOrderListContainer />
  </LazyLoader>
)
const UserSettings = () => (
  <LazyLoader>
    <UserSettingsContainer />
  </LazyLoader>
)
const UserHelp = () => (
  <LazyLoader>
    <UserHelpContainer />
  </LazyLoader>
)
const UserStore = () => (
  <LazyLoader>
    <UserStoreContainer />
  </LazyLoader>
)
const UserStoreList = () => (
  <LazyLoader>
    <UserStoreListContainer />
  </LazyLoader>
)
const UserReplenish = () => (
  <LazyLoader>
    <UserReplenishContainer />
  </LazyLoader>
)
const SiteMap = () => (
  <LazyLoader>
    <SiteMapContainer />
  </LazyLoader>
)

function routes() {
  return (
    <Switch>
      <Route exact path={ROUTE.NOT_FOUND} component={notFound} />
      {/* Auth */}
      <Route exact path={ROUTE.AUTH} component={Auth} />
      <Route exact path={ROUTE.AUTH_SIGNIN} component={AuthSignInEmail} />
      <Route exact path={ROUTE.AUTH_SIGNIN_PHONE} component={AuthSignInPhone} />
      <Route exact path={ROUTE.AUTH_SIGNUP} component={AuthSignUp} />
      {/* Main */}
      <Route exact path={ROUTE.MAIN} component={Main} />
      <Route exact path={ROUTE.MAIN_STORES} component={MainStores} />
      {/* Page */}
      <Route exact path={`${ROUTE.PAGE}/:key`} component={Page} />
      <Route exact path={ROUTE.SITE_MAP} component={SiteMap} />
      {/* Advert */}
      <Route
        exact
        path={`${ROUTE.ADVERT}/:slug`}
        // path={ROUTE.ADVERT}
        component={Advert}
      />
      <Route exact path={ROUTE.ADVERT_LIST} component={AdvertList} />
      <Route exact path={ROUTE.ADVERT_CREATE} component={AdvertCreate} />
      <Route exact path={ROUTE.ADVERT_SEARCH} component={AdvertSearch} />
      <Route exact path={`${ROUTE.STORE}/:name`} component={Store} />
      {/* Store */}
      <Route exact path={ROUTE.STORE_LIST} component={StoreList} />
      <Route exact path={ROUTE.STORE_FILTER_LIST} component={StoreFilterList} />
      <Route exact path={ROUTE.STORE_CREATE} component={StoreCreate} />
      <Route exact path={ROUTE.STORE_SEARCH} component={StoreSearch} />
      {/* User */}
      <Route exact path={ROUTE.USER_ADVERT_LIST} component={UserAdvertList} />
      <Route exact path={ROUTE.USER_ADVERT_FAVORITE_LIST} component={UserAdvertFavoriteList} />
      <Route exact path={ROUTE.USER_ADVERT_PACKS} component={UserAdvertPacks} />
      <Route exact path={ROUTE.USER_MESSAGE_LIST} component={UserMessageList} />
      <Route exact path={ROUTE.USER_CHAT_LIST} component={UserChatList} />
      <Route exact path={ROUTE.USER_ORDER_LIST} component={UserOrderList} />
      <Route exact path={ROUTE.USER_SETTINGS} component={UserSettings} />
      <Route exact path={ROUTE.USER_HELP} component={UserHelp} />
      <Route exact path={ROUTE.USER_STORE} component={UserStore} />
      <Route exact path={ROUTE.USER_STORE_LIST} component={UserStoreList} />
      <Route exact path={ROUTE.USER_REPLENISH} component={UserReplenish} />
      {/* Label */}
      <Route exact path={`${ROUTE.MAIN_STORES}/:category/:type_key`} component={StoreLabel} />
      <Route exact path={`${ROUTE.MAIN}:category/:type_key`} component={AdvertLabel} />
      {/* Example */}
      <Route exact path='/main' component={exampleMain} />
      <Route exact path='/full' component={exampleFullWidth} />
      <Route exact path='/text' component={exampleText} />
      <Redirect to={ROUTE.NOT_FOUND} />
    </Switch>
  )
}

export default routes
