import actionTypes, { CATEGORY, CATEGORY_LIST, CATEGORY_INFO } from './actionTypes'

export const categoryGet = payload => ({
  type: actionTypes[CATEGORY].pending,
  payload,
})

export const categoryClear = () => ({
  type: actionTypes[CATEGORY].clear,
})

export const categoryList = payload => ({
  type: actionTypes[CATEGORY_LIST].pending,
  payload,
})

export const categoryListClear = () => ({
  type: actionTypes[CATEGORY_LIST].clear,
})

export const categoryInfo = payload => ({
  type: actionTypes[CATEGORY_INFO].pending,
  payload,
})

export const categoryInfoClear = () => ({
  type: actionTypes[CATEGORY_INFO].clear,
})
