import uzMessages from '../locales/uz-UZ'

const UzLang = {
  messages: {
    ...uzMessages,
  },
  locale: 'uz-UZ',
}

export default UzLang
