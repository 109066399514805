import { CITY } from '../constants/storage'

export const getStorage = local => (local ? sessionStorage : localStorage)

export const getCity = () => localStorage.getItem(CITY) || sessionStorage.getItem(CITY)

export const setCity = (city, local = false) => {
  const storage = getStorage(local)

  storage.setItem(CITY, city)
}

export const removeCity = () => {
  localStorage.removeItem(CITY)
  sessionStorage.removeItem(CITY)
}
