import { combineReducers } from 'redux'
import createState from '../../helpers/createState'
import { CITY_SEARCH, CITY_ALL_SHORT, CITY_REGIONS } from './actionTypes'
import * as STATE from './state'

const reducer = combineReducers({
  [STATE.CITY_SEARCH]: createState(CITY_SEARCH),
  [STATE.CITY_ALL_SHORT]: createState(CITY_ALL_SHORT),
  [STATE.CITY_REGIONS]: createState(CITY_REGIONS),
})

export default reducer
