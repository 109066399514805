export const AUTH_SIGNIN_EMAIL_FORM = 'signInEmail'
export const AUTH_SIGNUP_FORM = 'authSignUp'

export const SEARCH_FORM = 'search'
export const FILTER_FORM = 'filter'
export const OPTIONS_FORM = 'options'
export const ADVERT_CREATE_FORM = 'advertCreate'
export const ADVERT_MESSAGE_CREATE_FORM = 'advertMessageCreate'
export const ADVERT_COMPLAIN_CREATE_FORM = 'advertComplainCreate'
export const STORE_CREATE_FORM = 'storeCreate'
export const STORE_CREATE_WORKING_HOURS_FORM = 'storeCreateWorkingHours'
export const STORE_USER_REVIEW_FORM = 'storeUserReview'
export const USER_PROFILE_UPDATE_FORM = 'userProfileUpdate'
