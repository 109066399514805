import { combineReducers } from 'redux'
import createState from '../../helpers/createState'
import { WEB_PAGE_SITE_MAP_INFO } from './actionTypes'
import * as STATE from './state'

const reducer = combineReducers({
  [STATE.WEB_PAGE_SITE_MAP_INFO]: createState(WEB_PAGE_SITE_MAP_INFO),
})

export default reducer
