import {
  actionPending,
  actionFulfilled,
  actionEdit,
  actionRejected,
  actionClear,
} from './createState'

function createAction(actionName) {
  return {
    pending: actionPending(actionName),
    fulfilled: actionFulfilled(actionName),
    edit: actionEdit(actionName),
    rejected: actionRejected(actionName),
    clear: actionClear(actionName),
  }
}

export default createAction
