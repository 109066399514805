import { pipe, propOr, split, equals, last } from 'ramda'
import { window } from 'ssr-window'
import { createStore, compose, applyMiddleware } from 'redux'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { createEpicMiddleware } from 'redux-observable'
import { ajax } from 'rxjs/ajax'
import thunk from 'redux-thunk'
import reducers from '../reducers/reducers'
import rootEpic from '../reducers/rootEpic'

export let history
if (typeof document !== 'undefined') {
  history = createBrowserHistory()
} else {
  history = createMemoryHistory()
}
const routeMiddleware = routerMiddleware(history)
const epicMiddleware = createEpicMiddleware({
  dependencies: { getJSON: ajax.getJSON },
})

const middlewares = [routeMiddleware, epicMiddleware, thunk]

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger')

  const logger = createLogger({
    collapsed: true,
    colors: {
      prevState: () => '#9E9E9E',
      action: () => '#03A9F4',
      nextState: () => '#4CAF50',
      error: () => '#F20404',
      title: action => {
        return pipe(
          actionType => last(split('_', actionType) || []),
          actionStatus => {
            return equals(actionStatus, 'FULFILLED')
              ? '#25c72e'
              : equals(actionStatus, 'REJECTED')
              ? '#c62c2c'
              : equals(actionStatus, 'PENDING')
              ? '#d2c22e'
              : equals(actionStatus, 'CLEAR')
              ? '#2E72E2FF'
              : '#FFF'
          }
        )(propOr('', 'type', action))
      },
    },
  })

  middlewares.push(logger)
}
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore(initialState = {}) {
  const store = createStore(
    reducers(history),
    initialState,
    composeEnhancer(applyMiddleware(...middlewares))
  )

  epicMiddleware.run(rootEpic)

  return store
}
