import React from 'react'
import ReactDOM from 'react-dom'
import AppClient from './AppClient'
import 'semantic-ui-less/semantic.less'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import './assets/css/index.css'
// import registerServiceWorker from './registerServiceWorker'
import 'react-imported-component/macro'

ReactDOM.hydrate(<AppClient />, document.getElementById('root'))

// rehydrateMarks().then(() => {
//   ReactDOM.hydrate(<AppClient />, document.getElementById('root'));
// })

// registerServiceWorker();
