import { prop, dissoc } from 'ramda'
import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError, mapTo } from 'rxjs/operators'
import { push } from 'connected-react-router'
import actionTypes, {
  USER_PROFILE_UPDATE,
  USER_PRODUCTS_ACTIVE,
  USER_PRODUCTS_COUNT,
  USER_PRODUCTS_DELETED,
  USER_STORES_ACTIVE,
  USER_STORES_COUNT,
  USER_STORES_DELETED,
  USER_STORE_NAME_CHECK,
  USER_STORE_EDIT,
  USER_STORE_ARCHIVE,
  USER_STORE_UNARCHIVE,
  USER_MESSAGES_ACTIVE,
  USER_PROMOTION_PRODUCT_INFO,
  USER_PROMOTION_STORE_INFO,
  PAYMENT_GENERATE,
  ACCOUNT_ADVERT_SUBSCRIBE,
  ACCOUNT_STORE_SUBSCRIBE,
  ACCOUNT_BALANCE,
  USER_ADVERT_FAVORITE,
  USER_ADVERT_FAVORITE_DELETE,
  USER_ADVERT_FAVORITE_LIST,
  USER_STORE_FAVORITE,
  USER_STORE_FAVORITE_DELETE,
  USER_STORE_FAVORITE_LIST,
  USER_REVIEW_EDIT,
  USER_REVIEW_DELETE,
  USER_REVIEW_LIST,
} from './actionTypes'
import * as API from '../../constants/api'
import * as ROUTE from '../../constants/routes'
import BarakaApi from '../../helpers/ajax'
import * as actions from '../../reducers/actions'
import { toastrError } from '../../helpers/toastr'

const api = new BarakaApi()

const updateUserProfileEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_PROFILE_UPDATE].pending),
    mergeMap(({ payload }) =>
      api.securedPut(API.USER_PROFILE_UPDATE_URL, null, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_PROFILE_UPDATE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_PROFILE_UPDATE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getUserProductsActiveEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_PRODUCTS_ACTIVE].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.USER_PRODUCTS_ALL_ACTIVE_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_PRODUCTS_ACTIVE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_PRODUCTS_ACTIVE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getUserProductsCountEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_PRODUCTS_COUNT].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.USER_PRODUCTS_ALL_COUNT_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_PRODUCTS_COUNT].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_PRODUCTS_COUNT].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getUserProductsDeletedEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_PRODUCTS_DELETED].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.USER_PRODUCTS_ALL_DELETED_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_PRODUCTS_DELETED].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_PRODUCTS_DELETED].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getUserStoresActiveEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_STORES_ACTIVE].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.WEB_STORE_OWN_ACTIVE_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_STORES_ACTIVE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_STORES_ACTIVE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getUserStoresCountEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_STORES_COUNT].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.WEB_STORE_ALL_COUNT_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_STORES_COUNT].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_STORES_COUNT].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getUserStoresDeletedEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_STORES_DELETED].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.WEB_STORE_OWN_INACTIVE_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_STORES_DELETED].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_STORES_DELETED].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getUserStoreNameCheckEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_STORE_NAME_CHECK].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.WEB_STORE_NAME_CHECK_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_STORE_NAME_CHECK].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_STORE_NAME_CHECK].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getUserStoreEditEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_STORE_EDIT].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.WEB_STORE_EDIT_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_STORE_EDIT].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_STORE_EDIT].rejected,
            payload: response,
          })
        )
      )
    )
  )

const putUserStoreArchiveEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_STORE_ARCHIVE].pending),
    mergeMap(({ payload }) =>
      api
        .securedPut(`${API.WEB_STORE_ARCHIVE_URL}${prop('id', payload)}`, dissoc('id', payload))
        .pipe(
          map(({ response }) => ({
            type: actionTypes[USER_STORE_ARCHIVE].fulfilled,
            payload: response,
          })),
          catchError(response =>
            of({
              type: actionTypes[USER_STORE_ARCHIVE].rejected,
              payload: response,
            })
          )
        )
    )
  )

const putUserStoreArchiveFulfilledEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_STORE_ARCHIVE].fulfilled),
    mapTo(push(ROUTE.USER_STORE_LIST))
  )

const putUserStoreUnarchiveEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_STORE_UNARCHIVE].pending),
    mergeMap(({ payload }) =>
      api
        .securedPut(`${API.WEB_STORE_UNARCHIVE_URL}${prop('id', payload)}`, dissoc('id', payload))
        .pipe(
          map(({ response }) => ({
            type: actionTypes[USER_STORE_UNARCHIVE].fulfilled,
            payload: response,
          })),
          catchError(response =>
            of({
              type: actionTypes[USER_STORE_UNARCHIVE].rejected,
              payload: response,
            })
          )
        )
    )
  )

const putUserStoreUnarchiveFulfilledEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_STORE_UNARCHIVE].fulfilled),
    mapTo(push(ROUTE.USER_STORE_LIST))
  )

const getUserMessagesActiveEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_MESSAGES_ACTIVE].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.PRODUCT_MESSAGE_ACTIVE_ALL_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_MESSAGES_ACTIVE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_MESSAGES_ACTIVE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getUserPromotionProductsInfoEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_PROMOTION_PRODUCT_INFO].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.WEB_PAGE_PROMOTION_PRODUCT_INFO_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_PROMOTION_PRODUCT_INFO].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_PROMOTION_PRODUCT_INFO].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getUserPromotionProductsInfoRejectedEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_PROMOTION_PRODUCT_INFO].rejected),
    map(({ payload: { response } }) =>
      toastrError({
        id: Math.floor(Math.random() * 1000),
        title: prop('error', response),
        message: prop('message', response),
      })
    )
  )

const getUserPromotionStoreInfoEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_PROMOTION_STORE_INFO].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.WEB_PAGE_PROMOTION_STORE_INFO_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_PROMOTION_STORE_INFO].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_PROMOTION_STORE_INFO].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getPaymentGenerateEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[PAYMENT_GENERATE].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.PAYMENT_GENERATE_URL_URL, payload).pipe(
        map(({ response }) => {
          return {
            type: actionTypes[PAYMENT_GENERATE].fulfilled,
            payload: response,
          }
        }),
        catchError(response =>
          of({
            type: actionTypes[PAYMENT_GENERATE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getPaymentGenerateFulfilledEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[PAYMENT_GENERATE].fulfilled),
    mergeMap(({ payload }) => {
      return window.open(payload.url)
    })
  )

const postAccountAdvertSubscribeEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ACCOUNT_ADVERT_SUBSCRIBE].pending),
    mergeMap(({ payload }) =>
      api.securedPost(API.ACCOUNT_ADVERT_SUBSCRIBE_URL, null, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[ACCOUNT_ADVERT_SUBSCRIBE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ACCOUNT_ADVERT_SUBSCRIBE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const postAccountAdvertSubscribeFulfilledEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ACCOUNT_ADVERT_SUBSCRIBE].fulfilled),
    map(({ payload: { response } }) =>
      toastrError({
        id: Math.floor(Math.random() * 1000),
        title: 'Успех',
        message: 'Transaction completed successfully',
      })
    )
  )

const postAccountAdvertSubscribeRejectedEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ACCOUNT_ADVERT_SUBSCRIBE].rejected),
    map(({ payload: { response } }) =>
      toastrError({
        id: Math.floor(Math.random() * 1000),
        title: 'Ошибка',
        message: 'Недостаточно средств',
      })
    )
  )

const postAccountStoreSubscribeEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ACCOUNT_STORE_SUBSCRIBE].pending),
    mergeMap(({ payload }) =>
      api.securedPost(API.ACCOUNT_STORE_SUBSCRIBE_URL, null, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[ACCOUNT_STORE_SUBSCRIBE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ACCOUNT_STORE_SUBSCRIBE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const postAccountStoreSubscribeFulfilledEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ACCOUNT_STORE_SUBSCRIBE].fulfilled),
    map(({ payload: { response } }) =>
      toastrError({
        id: Math.floor(Math.random() * 1000),
        title: 'Успех',
        message: 'Transaction completed successfully',
      })
    )
  )

const postAccountStoreSubscribeRejectedEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ACCOUNT_STORE_SUBSCRIBE].rejected),
    map(({ payload: { response } }) =>
      toastrError({
        id: Math.floor(Math.random() * 1000),
        title: 'Ошибка',
        message: 'Недостаточно средств',
      })
    )
  )

const getAccountBalanceEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[ACCOUNT_BALANCE].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.ACCOUNT_BALANCE_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[ACCOUNT_BALANCE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[ACCOUNT_BALANCE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const postUserAdvertFavoriteEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_ADVERT_FAVORITE].pending),
    mergeMap(({ payload }) =>
      api.securedPost(`${API.WEB_FAVORITE_PRODUCTS_URL}${prop('id', payload)}`).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_ADVERT_FAVORITE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_ADVERT_FAVORITE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const deleteUserAdvertFavoriteEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_ADVERT_FAVORITE_DELETE].pending),
    mergeMap(({ payload }) =>
      api.securedDelete(`${API.WEB_FAVORITE_PRODUCTS_URL}${prop('id', payload)}`).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_ADVERT_FAVORITE_DELETE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_ADVERT_FAVORITE_DELETE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getUserAdvertFavoriteListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_ADVERT_FAVORITE_LIST].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.WEB_FAVORITE_PRODUCTS_URL).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_ADVERT_FAVORITE_LIST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_ADVERT_FAVORITE_LIST].rejected,
            payload: response,
          })
        )
      )
    )
  )

const postUserStoreFavoriteEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_STORE_FAVORITE].pending),
    mergeMap(({ payload }) =>
      api.securedPost(`${API.WEB_FAVORITE_STORES_URL}${prop('id', payload)}`).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_STORE_FAVORITE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_STORE_FAVORITE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const deleteUserStoreFavoriteEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_STORE_FAVORITE_DELETE].pending),
    mergeMap(({ payload }) =>
      api.securedDelete(`${API.WEB_FAVORITE_STORES_URL}${prop('id', payload)}`).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_STORE_FAVORITE_DELETE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_STORE_FAVORITE_DELETE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getUserStoreFavoriteListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_STORE_FAVORITE_LIST].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.WEB_FAVORITE_STORES_URL).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_STORE_FAVORITE_LIST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_STORE_FAVORITE_LIST].rejected,
            payload: response,
          })
        )
      )
    )
  )

const postUserReviewEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_REVIEW_EDIT].pending),
    mergeMap(({ payload }) =>
      api.securedPost(API.WEB_REVIEW_URL, null, dissoc('name', payload)).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_REVIEW_EDIT].fulfilled,
          payload: response,
          args: payload,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_REVIEW_EDIT].rejected,
            payload: response,
          })
        )
      )
    )
  )

const postUserReviewFulfilledEpic = (action$, state) =>
  action$.pipe(
    ofType(actionTypes[USER_REVIEW_EDIT].fulfilled),
    map(({ args: { name } }) =>
      actions.storeName({
        name,
        token: state.value.auth.token.data,
      })
    )
  )

const deleteUserReviewEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_REVIEW_DELETE].pending),
    mergeMap(({ payload }) =>
      api.securedDelete(`${API.WEB_REVIEW_URL}${prop('id', payload)}`).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_REVIEW_DELETE].fulfilled,
          payload: response,
          args: payload,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_REVIEW_DELETE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const deleteUserReviewFulfilledEpic = (action$, state) =>
  action$.pipe(
    ofType(actionTypes[USER_REVIEW_DELETE].fulfilled),
    map(({ args: { name } }) => {
      return actions.storeName({
        name,
        token: state.value.auth.token.data,
      })
    })
  )

const getUserReviewListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_REVIEW_LIST].pending),
    mergeMap(({ payload }) =>
      api.securedGet(`${API.WEB_REVIEW_URL}${prop('name', payload)}/`).pipe(
        map(({ response }) => ({
          type: actionTypes[USER_REVIEW_LIST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[USER_REVIEW_LIST].rejected,
            payload: response,
          })
        )
      )
    )
  )

export default combineEpics(
  updateUserProfileEpic,
  getUserProductsActiveEpic,
  getUserProductsCountEpic,
  getUserProductsDeletedEpic,
  getUserStoresActiveEpic,
  getUserStoresCountEpic,
  getUserStoresDeletedEpic,
  getUserStoreNameCheckEpic,
  getUserStoreEditEpic,
  putUserStoreArchiveEpic,
  putUserStoreArchiveFulfilledEpic,
  putUserStoreUnarchiveEpic,
  putUserStoreUnarchiveFulfilledEpic,
  getUserMessagesActiveEpic,
  getUserPromotionProductsInfoEpic,
  getUserPromotionProductsInfoRejectedEpic,
  getUserPromotionStoreInfoEpic,
  getPaymentGenerateEpic,
  getPaymentGenerateFulfilledEpic,
  postAccountAdvertSubscribeEpic,
  postAccountAdvertSubscribeFulfilledEpic,
  postAccountAdvertSubscribeRejectedEpic,
  postAccountStoreSubscribeEpic,
  postAccountStoreSubscribeFulfilledEpic,
  postAccountStoreSubscribeRejectedEpic,
  getAccountBalanceEpic,
  postUserAdvertFavoriteEpic,
  deleteUserAdvertFavoriteEpic,
  getUserAdvertFavoriteListEpic,
  postUserStoreFavoriteEpic,
  deleteUserStoreFavoriteEpic,
  getUserStoreFavoriteListEpic,
  postUserReviewEpic,
  postUserReviewFulfilledEpic,
  deleteUserReviewEpic,
  deleteUserReviewFulfilledEpic,
  getUserReviewListEpic
)
