import createAction from '../../helpers/createAction'

export const TOKEN = 'TOKEN'
export const TOKEN_CLEAR = 'TOKEN_CLEAR'
export const SIGN_IN = 'SIGN_IN'
export const SIGN_UP = 'SIGN_UP'
export const SIGN_UP_PHONE = 'SIGN_UP_PHONE'
export const SIGN_UP_REGISTER = 'SIGN_UP_REGISTER'
export const USER_INFO = 'USER_INFO'
export const MAIN_INFO = 'MAIN_INFO'

export default {
  [SIGN_IN]: createAction(SIGN_IN),
  [SIGN_UP]: createAction(SIGN_UP),
  [SIGN_UP_PHONE]: createAction(SIGN_UP_PHONE),
  [SIGN_UP_REGISTER]: createAction(SIGN_UP_REGISTER),
  [USER_INFO]: createAction(USER_INFO),
  [MAIN_INFO]: createAction(MAIN_INFO),
}
