import createAction from '../../helpers/createAction'

const RUBRIC = 'RUBRIC'
const RUBRIC_LIST = 'RUBRIC_LIST'

export default {
  [RUBRIC]: createAction(RUBRIC),
  [RUBRIC_LIST]: createAction(RUBRIC_LIST),
}

export { RUBRIC, RUBRIC_LIST }
