export const USER_PRODUCT_LIST_ACTIVE = 'productListActive'
export const USER_PRODUCT_LIST_COUNT = 'productListCount'
export const USER_PRODUCT_LIST_DELETED = 'productListDeleted'
export const USER_STORE_LIST_ACTIVE = 'storeListActive'
export const USER_STORE_LIST_COUNT = 'storeListCount'
export const USER_STORE_LIST_DELETED = 'storeListDeleted'
export const USER_STORE_NAME_CHECK = 'storeNameCheck'
export const USER_MESSAGE_LIST_ACTIVE = 'messageListActive'
export const USER_MESSAGE_LIST_COUNT = 'messageListCount'
export const USER_MESSAGE_LIST_DELETED = 'messageListDeleted'
export const USER_PROMOTION_PRODUCT_INFO = 'promotionProductInfo'
export const USER_PROMOTION_STORE_INFO = 'promotionStoreInfo'
export const USER_ACCOUNT_BALANCE = 'accountBalance'
export const USER_ADVERT_FAVORITE_LIST = 'advertFavoriteList'
export const USER_STORE_FAVORITE_LIST = 'storeFavoriteList'
export const USER_REVIEW_LIST = 'reviewList'
