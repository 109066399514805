import { combineReducers } from 'redux'
import createState from '../../helpers/createState'
import {
  ADVERT,
  ADVERT_EDIT,
  ADVERT_LIST,
  ADVERT_FILTER_LIST,
  ADVERT_SUGGEST,
  ADVERT_SIMILAR,
  ADVERT_USER,
  ADVERT_COMPLAIN_LIST,
} from './actionTypes'
import * as STATE from './state'

const reducer = combineReducers({
  [STATE.ADVERT_ITEM]: createState(ADVERT),
  [STATE.ADVERT_EDIT]: createState(ADVERT_EDIT),
  [STATE.ADVERT_LIST]: createState(ADVERT_LIST),
  [STATE.ADVERT_FILTER_LIST]: createState(ADVERT_FILTER_LIST),
  [STATE.ADVERT_SUGGEST]: createState(ADVERT_SUGGEST),
  [STATE.ADVERT_SIMILAR]: createState(ADVERT_SIMILAR),
  [STATE.ADVERT_MY_ADVERT]: createState(ADVERT_USER),
  [STATE.ADVERT_COMPLAIN_LIST]: createState(ADVERT_COMPLAIN_LIST),
})

export default reducer
