import { ajax } from 'rxjs/ajax'
import queryString from 'query-string'
import * as api from '../constants/api'
import { getToken } from './tokenStorage'
import { getLanguage } from './languageStorage'

const language = getLanguage()

export default class BarakaApi {
  get(url, params, headers) {
    const defaultUrl = url || api.API_URL
    const query = queryString.stringify(params)
    const URL = params ? `${defaultUrl}?${query}` : defaultUrl

    return ajax.get(URL, {
      'Content-Type': 'application/json;charset=utf-8',
      'Accept-Language': language,
      ...headers,
    })
  }

  post(url, params, body, headers) {
    const defaultUrl = url || api.API_URL
    const query = queryString.stringify(params)
    const URL = params ? `${defaultUrl}?${query}` : defaultUrl

    return ajax.post(URL, body, {
      'Content-Type': 'application/json;charset=utf-8',
      'Accept-Language': language,
      ...headers,
    })
  }

  put(url, params, body, headers) {
    const defaultUrl = url || api.API_URL
    const query = queryString.stringify(params)
    const URL = params ? `${defaultUrl}?${query}` : defaultUrl

    return ajax.put(URL, body, {
      'Content-Type': 'application/json;charset=utf-8',
      'Accept-Language': language,
      ...headers,
    })
  }

  patch(url, params, body, headers) {
    const defaultUrl = url || api.API_URL
    const query = queryString.stringify(params)
    const URL = params ? `${defaultUrl}?${query}` : defaultUrl

    return ajax.patch(URL, body, {
      'Content-Type': 'application/json;charset=utf-8',
      'Accept-Language': language,
      ...headers,
    })
  }

  delete(url, params, headers) {
    const defaultUrl = url || api.API_URL
    const query = queryString.stringify(params)
    const URL = params ? `${defaultUrl}?${query}` : defaultUrl

    return ajax.delete(URL, {
      'Content-Type': 'application/json;charset=utf-8',
      'Accept-Language': language,
      ...headers,
    })
  }

  securedGet(url, params, headers) {
    const defaultUrl = url || api.API_URL
    const query = queryString.stringify(params)
    const URL = params ? `${defaultUrl}?${query}` : defaultUrl

    const token = getToken()

    return ajax.get(URL, {
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept-Language': language,
      Authorization: token && `Bearer ${token}`,
      ...headers,
    })
  }

  securedPost(url, params, body, headers) {
    const defaultUrl = url || api.API_URL
    const query = queryString.stringify(params)
    const URL = params ? `${defaultUrl}?${query}` : defaultUrl

    const token = getToken()

    return ajax.post(URL, body, {
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept-Language': language,
      Authorization: token && `Bearer ${token}`,
      ...headers,
    })
  }

  securedPut(url, params, body, headers) {
    const defaultUrl = url || api.API_URL
    const query = queryString.stringify(params)
    const URL = params ? `${defaultUrl}?${query}` : defaultUrl

    const token = getToken()

    return ajax.put(URL, body, {
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept-Language': language,
      Authorization: token && `Bearer ${token}`,
      ...headers,
    })
  }

  securedPatch(url, params, body, headers) {
    const defaultUrl = url || api.API_URL
    const query = queryString.stringify(params)
    const URL = params ? `${defaultUrl}?${query}` : defaultUrl

    const token = getToken()

    return ajax.patch(URL, body, {
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept-Language': language,
      Authorization: token && `Bearer ${token}`,
      ...headers,
    })
  }

  securedDelete(url, params, headers) {
    const defaultUrl = url || api.API_URL
    const query = queryString.stringify(params)
    const URL = params ? `${defaultUrl}?${query}` : defaultUrl

    const token = getToken()

    return ajax.delete(URL, {
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept-Language': language,
      Authorization: token && `Bearer ${token}`,
      ...headers,
    })
  }
}
