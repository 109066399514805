import { combineReducers } from 'redux'
import createState from '../../helpers/createState'
import { RUBRIC, RUBRIC_LIST } from './actionTypes'
import * as STATE from './state'

const reducer = combineReducers({
  [STATE.RUBRIC_ITEM]: createState(RUBRIC),
  [STATE.RUBRIC_LIST]: createState(RUBRIC_LIST),
})

export default reducer
