import { createIntl } from 'react-intl'
import enLang from './entries/en-US'
import ruLang from './entries/ru-RU'
import uzLang from './entries/uz-UZ'

const AppLocale = {
  en: enLang,
  ru: ruLang,
  uz: uzLang,
}
createIntl(AppLocale.en.data)
createIntl(AppLocale.ru.data)
createIntl(AppLocale.uz.data)

export default AppLocale
