import createAction from '../../helpers/createAction'

export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE'

export const USER_PRODUCTS_ACTIVE = 'USER_PRODUCTS_ACTIVE'
export const USER_PRODUCTS_COUNT = 'USER_PRODUCTS_COUNT'
export const USER_PRODUCTS_DELETED = 'USER_PRODUCTS_DELETED'

export const USER_STORES_ACTIVE = 'USER_STORES_ACTIVE'
export const USER_STORES_COUNT = 'USER_STORES_COUNT'
export const USER_STORES_DELETED = 'USER_STORES_DELETED'

export const USER_STORE_NAME_CHECK = 'USER_STORE_NAME_CHECK'
export const USER_STORE_EDIT = 'USER_STORE_EDIT'
export const USER_STORE_ARCHIVE = 'USER_STORE_ARCHIVE'
export const USER_STORE_UNARCHIVE = 'USER_STORE_UNARCHIVE'

export const USER_MESSAGES_ACTIVE = 'USER_MESSAGES_ACTIVE'
export const USER_MESSAGES_COUNT = 'USER_MESSAGES_COUNT'
export const USER_MESSAGES_DELETED = 'USER_MESSAGES_DELETED'

export const USER_PROMOTION_PRODUCT_INFO = 'USER_PROMOTION_PRODUCT_INFO'
export const USER_PROMOTION_STORE_INFO = 'USER_PROMOTION_STORE_INFO'

export const PAYMENT_GENERATE = 'PAYMENT_GENERATE'

export const ACCOUNT_ADVERT_SUBSCRIBE = 'ACCOUNT_ADVERT_SUBSCRIBE'
export const ACCOUNT_STORE_SUBSCRIBE = 'ACCOUNT_STORE_SUBSCRIBE'
export const ACCOUNT_BALANCE = 'ACCOUNT_BALANCE'

export const USER_ADVERT_FAVORITE = 'USER_ADVERT_FAVORITE'
export const USER_ADVERT_FAVORITE_DELETE = 'USER_ADVERT_FAVORITE_DELETE'
export const USER_ADVERT_FAVORITE_LIST = 'USER_ADVERT_FAVORITE_LIST'

export const USER_STORE_FAVORITE = 'USER_STORE_FAVORITE'
export const USER_STORE_FAVORITE_DELETE = 'USER_STORE_FAVORITE_DELETE'
export const USER_STORE_FAVORITE_LIST = 'USER_STORE_FAVORITE_LIST'

export const USER_REVIEW_EDIT = 'USER_REVIEW_EDIT'
export const USER_REVIEW_DELETE = 'USER_REVIEW_DELETE'
export const USER_REVIEW_LIST = 'USER_REVIEW_LIST'

export default {
  [USER_PROFILE_UPDATE]: createAction(USER_PROFILE_UPDATE),
  [USER_PRODUCTS_ACTIVE]: createAction(USER_PRODUCTS_ACTIVE),
  [USER_PRODUCTS_COUNT]: createAction(USER_PRODUCTS_COUNT),
  [USER_PRODUCTS_DELETED]: createAction(USER_PRODUCTS_DELETED),
  [USER_STORES_ACTIVE]: createAction(USER_STORES_ACTIVE),
  [USER_STORES_COUNT]: createAction(USER_STORES_COUNT),
  [USER_STORES_DELETED]: createAction(USER_STORES_DELETED),
  [USER_STORE_NAME_CHECK]: createAction(USER_STORE_NAME_CHECK),
  [USER_STORE_EDIT]: createAction(USER_STORE_EDIT),
  [USER_STORE_ARCHIVE]: createAction(USER_STORE_ARCHIVE),
  [USER_STORE_UNARCHIVE]: createAction(USER_STORE_UNARCHIVE),
  [USER_MESSAGES_ACTIVE]: createAction(USER_MESSAGES_ACTIVE),
  [USER_MESSAGES_COUNT]: createAction(USER_MESSAGES_COUNT),
  [USER_MESSAGES_DELETED]: createAction(USER_MESSAGES_DELETED),
  [USER_PROMOTION_PRODUCT_INFO]: createAction(USER_PROMOTION_PRODUCT_INFO),
  [USER_PROMOTION_STORE_INFO]: createAction(USER_PROMOTION_STORE_INFO),
  [PAYMENT_GENERATE]: createAction(PAYMENT_GENERATE),
  [ACCOUNT_ADVERT_SUBSCRIBE]: createAction(ACCOUNT_ADVERT_SUBSCRIBE),
  [ACCOUNT_STORE_SUBSCRIBE]: createAction(ACCOUNT_STORE_SUBSCRIBE),
  [ACCOUNT_BALANCE]: createAction(ACCOUNT_BALANCE),
  [USER_ADVERT_FAVORITE]: createAction(USER_ADVERT_FAVORITE),
  [USER_ADVERT_FAVORITE_DELETE]: createAction(USER_ADVERT_FAVORITE_DELETE),
  [USER_ADVERT_FAVORITE_LIST]: createAction(USER_ADVERT_FAVORITE_LIST),
  [USER_STORE_FAVORITE]: createAction(USER_STORE_FAVORITE),
  [USER_STORE_FAVORITE_DELETE]: createAction(USER_STORE_FAVORITE_DELETE),
  [USER_STORE_FAVORITE_LIST]: createAction(USER_STORE_FAVORITE_LIST),
  [USER_REVIEW_EDIT]: createAction(USER_REVIEW_EDIT),
  [USER_REVIEW_DELETE]: createAction(USER_REVIEW_DELETE),
  [USER_REVIEW_LIST]: createAction(USER_REVIEW_LIST),
}
