import { combineReducers } from 'redux'
import createState from '../../helpers/createState'
import {
  USER_PRODUCTS_ACTIVE,
  USER_PRODUCTS_COUNT,
  USER_PRODUCTS_DELETED,
  USER_STORES_ACTIVE,
  USER_STORES_COUNT,
  USER_STORES_DELETED,
  USER_STORE_NAME_CHECK,
  USER_MESSAGES_ACTIVE,
  USER_MESSAGES_COUNT,
  USER_MESSAGES_DELETED,
  USER_PROMOTION_PRODUCT_INFO,
  USER_PROMOTION_STORE_INFO,
  ACCOUNT_BALANCE,
  USER_ADVERT_FAVORITE_LIST,
  USER_STORE_FAVORITE_LIST,
  USER_REVIEW_LIST,
} from './actionTypes'
import * as STATE from './state'

const reducer = combineReducers({
  [STATE.USER_PRODUCT_LIST_ACTIVE]: createState(USER_PRODUCTS_ACTIVE),
  [STATE.USER_PRODUCT_LIST_COUNT]: createState(USER_PRODUCTS_COUNT),
  [STATE.USER_PRODUCT_LIST_DELETED]: createState(USER_PRODUCTS_DELETED),
  [STATE.USER_STORE_LIST_ACTIVE]: createState(USER_STORES_ACTIVE),
  [STATE.USER_STORE_LIST_COUNT]: createState(USER_STORES_COUNT),
  [STATE.USER_STORE_LIST_DELETED]: createState(USER_STORES_DELETED),
  [STATE.USER_STORE_NAME_CHECK]: createState(USER_STORE_NAME_CHECK),
  [STATE.USER_MESSAGE_LIST_ACTIVE]: createState(USER_MESSAGES_ACTIVE),
  [STATE.USER_MESSAGE_LIST_COUNT]: createState(USER_MESSAGES_COUNT),
  [STATE.USER_MESSAGE_LIST_DELETED]: createState(USER_MESSAGES_DELETED),
  [STATE.USER_PROMOTION_PRODUCT_INFO]: createState(USER_PROMOTION_PRODUCT_INFO),
  [STATE.USER_PROMOTION_STORE_INFO]: createState(USER_PROMOTION_STORE_INFO),
  [STATE.USER_ACCOUNT_BALANCE]: createState(ACCOUNT_BALANCE),
  [STATE.USER_ADVERT_FAVORITE_LIST]: createState(USER_ADVERT_FAVORITE_LIST),
  [STATE.USER_STORE_FAVORITE_LIST]: createState(USER_STORE_FAVORITE_LIST),
  [STATE.USER_REVIEW_LIST]: createState(USER_REVIEW_LIST),
})

export default reducer
