import createAction from '../../helpers/createAction'

const CATEGORY = 'CATEGORY'
const CATEGORY_LIST = 'CATEGORY_LIST'
const CATEGORY_INFO = 'CATEGORY_INFO'

export default {
  [CATEGORY]: createAction(CATEGORY),
  [CATEGORY_LIST]: createAction(CATEGORY_LIST),
  [CATEGORY_INFO]: createAction(CATEGORY_INFO),
}

export { CATEGORY, CATEGORY_LIST, CATEGORY_INFO }
