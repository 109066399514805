import createReducer from '../../helpers/createReducer'
import { LANGUAGE, REGION, CITY, DISTRICT } from './actionTypes'
import * as STATE from './state'
import { setLanguage } from '../../helpers/languageStorage'
import { setRegion } from '../../helpers/regionStorage'
import { setCity } from '../../helpers/cityStorage'
import { setDistrict } from '../../helpers/districtStorage'

const defaultState = {
  [STATE.SETTINGS_LANGUAGE]: 'ru',
  [STATE.SETTINGS_REGION]: undefined,
  [STATE.SETTINGS_CITY]: undefined,
  [STATE.SETTINGS_DISTRICT]: undefined,
}

const reducers = {
  [LANGUAGE](state, { payload }) {
    setLanguage(payload)
    window.location.reload()

    return {
      ...state,
      [STATE.SETTINGS_LANGUAGE]: payload,
    }
  },
  [REGION](state, { payload }) {
    setRegion(payload)
    return {
      ...state,
      [STATE.SETTINGS_REGION]: payload,
    }
  },
  [CITY](state, { payload }) {
    setCity(payload)
    return {
      ...state,
      [STATE.SETTINGS_CITY]: payload,
    }
  },
  [DISTRICT](state, { payload }) {
    setDistrict(payload)
    return {
      ...state,
      [STATE.SETTINGS_DISTRICT]: payload,
    }
  },
}

export default createReducer(defaultState, reducers)
