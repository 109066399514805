export default {
  'general.copyright': 'baraka © 2020 All Rights Reserved.',
  'general.location': 'location',
  'general.district': 'District',
  'general.city': 'City',
  'general.byCBUCurse': 'по курсу ЦБ РУз',

  home: 'home',
  english: 'english',
  russian: 'russian',
  uzbek: 'uzbek',
  adverts: 'adverts',
  shops: 'shops',
  messages: 'messages',
  'choose-a-place': 'choose a place',
  'post-add-title': 'Post an ad on Baraka',
  search: 'search',
  'search-placeholder': 'what were you looking for...',
  'searching-results': 'searching results',
  'create-product': 'place an ad ',
  'choose-category': 'choose category',
  'my-adverts': 'my adverts',
  'my-messages': 'my messages',
  smsVerificationError: 'Sms code is incorrect',
  signUpViaPhone: 'Sign up with phone',
}
