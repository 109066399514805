import { LANGUAGE } from '../constants/storage'
import { isBrowser } from '../helpers/is'

const storage = isBrowser && localStorage

export const getLanguage = () => storage && storage.getItem(LANGUAGE)

export const setLanguage = lang => {
  storage && storage.setItem(LANGUAGE, lang)
}

export const removeLanguage = () => {
  storage && storage.removeItem(LANGUAGE)
}
