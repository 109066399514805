import { prop } from 'ramda'
import { actions } from 'react-redux-toastr'

export const toastrSuccess = props =>
  actions.add({
    id: prop('id', props),
    type: 'success',
    position: 'top-right',
    attention: true,
    title: prop('title', props),
    message: prop('message', props),
    onAttentionClick: id => {},
    options: prop('options', props),
  })

export const toastrError = props =>
  actions.add({
    id: prop('id', props),
    type: 'error',
    position: 'top-right',
    attention: true,
    title: prop('title', props),
    message: prop('message', props),
    onAttentionClick: id => {},
    options: prop('options', props),
  })

export const toastrWarning = props =>
  actions.add({
    id: prop('id', props),
    type: 'warning',
    position: 'top-right',
    attention: true,
    title: prop('title', props),
    message: prop('message', props),
    onAttentionClick: id => {},
    options: prop('options', props),
  })
