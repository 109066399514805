import { REGION } from '../constants/storage'

export const getStorage = local => (local ? sessionStorage : localStorage)

export const getRegion = () => localStorage.getItem(REGION) || sessionStorage.getItem(REGION)

export const setRegion = (city, local = false) => {
  const storage = getStorage(local)

  storage.setItem(REGION, city)
}

export const removeRegion = () => {
  localStorage.removeItem(REGION)
  sessionStorage.removeItem(REGION)
}
