import React from 'react'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import IntlProvider from './IntlProvider'
import RouterProvider from './RouterProvider'
import configureStore, { history } from './store/configureStore'

const store = configureStore(window.__REDUX_STATE__ || {})

function AppClient(props) {
  // console.log(
  //   '%cSend cv to slashdash@info.dev',
  //   'background: red; color: yellow; font-size: x-large'
  // )
  // eslint-disable-next-line no-console

  return (
    <Provider store={store}>
      <IntlProvider>
        <RouterProvider history={history} />
      </IntlProvider>

      <ReduxToastr
        timeOut={2000}
        newestOnTop={false}
        preventDuplicates
        position='top-right'
        transitionIn='fadeIn'
        transitionOut='fadeOut'
        progressBar
        closeOnToastrClick
      />
    </Provider>
  )
}

export default AppClient
