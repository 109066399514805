import { prop, propOr } from 'ramda'
import actionTypes, {
  SOCKET,
  SOCKET_APP_SEND_MESSAGE,
  SOCKET_USER_QUEUE_MESSAGES,
  CHAT_CHECK,
  CHAT_CREATE,
  CHAT_LIST,
  CHAT_MESSAGES,
} from './actionTypes'
import { Client } from '@stomp/stompjs'
import * as API from '../../constants/api'
import { NEW_CHAT_MESSAGE } from '../../constants/SocketMessageType'
import * as actions from '../../reducers/actions'
import { getToken } from '../../helpers/tokenStorage'
import * as getStore from '../../helpers/getStore'

export const socket = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes[SOCKET].pending,
      payload,
    })

    const token = getToken()

    const socket = new Client({
      brokerURL: API.SOCKET_URL,
      connectHeaders: {
        login: `Bearer ${token}`,
      },
      reconnectDelay: 10000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
    })

    socket.onConnect = function (frame) {
      dispatch({
        type: actionTypes[SOCKET].fulfilled,
        payload: socket,
      })

      socket.subscribe(API.SOCKET_SUBSCRIPTION_URL, props => console.warn(props))
    }

    socket.onStompError = function (frame) {
      dispatch({
        type: actionTypes[SOCKET].clear,
        payload: socket,
      })
    }

    socket.activate()
  }
}

export const socketUserQueueMessages = payload => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes[SOCKET_USER_QUEUE_MESSAGES].pending,
      payload,
    })

    const { data: socket } = getStore.socket(getState())

    const chatId = prop('chat_id', payload)
    const text = prop('text', payload)

    if (socket && chatId) {
      socket.subscribe(
        API.SOCKET_USER_QUEUE_MESSAGES(chatId),
        ({ body }) => {
          const data = JSON.parse(body)

          const { data: chatMessages } = getStore.chatMessages(getState())
          const chatMessagesContent = propOr([], 'content', chatMessages)

          dispatch(
            actions.chatMessagesEdit({
              ...chatMessages,
              content: [{ ...data, message_type: 'TEXT' }, ...chatMessagesContent],
            })
          )
        },
        { chatRoomId: chatId }
      )

      dispatch({
        type: actionTypes[SOCKET_USER_QUEUE_MESSAGES].fulfilled,
        payload: chatId,
      })

      if (text) {
        dispatch(
          actions.socketAppSendMessage({
            text,
            chat_id: chatId,
          })
        )
      }
    } else {
      dispatch({
        type: actionTypes[SOCKET_USER_QUEUE_MESSAGES].rejected,
        payload: 'socket is undefined',
      })
    }
  }
}

export const socketAppSendMessage = payload => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes[SOCKET_APP_SEND_MESSAGE].pending,
      payload,
    })

    const { data: socket } = getStore.socket(getState())

    const text = prop('text', payload)
    const chatRoomId = prop('chat_id', payload)

    if (socket) {
      const body = {
        type: NEW_CHAT_MESSAGE,
        data: {
          chat_id: chatRoomId,
          message_type: 0,
          message: text,
        },
      }

      socket.publish({
        destination: API.SOCKET_APP_SEND_MESSAGE,
        body: JSON.stringify(body),
        headers: {
          chatRoomId,
        },
      })

      dispatch({
        type: actionTypes[SOCKET_APP_SEND_MESSAGE].fulfilled,
        payload: {
          text,
          chatRoomId,
        },
      })
    } else {
      dispatch({
        type: actionTypes[SOCKET_APP_SEND_MESSAGE].rejected,
        payload: 'socket is undefined',
      })
    }
  }
}

export const chatCheck = payload => ({
  type: actionTypes[CHAT_CHECK].pending,
  payload,
})

export const chatCheckEdit = payload => ({
  type: actionTypes[CHAT_CHECK].edit,
  payload,
})

export const chatCheckClear = () => ({
  type: actionTypes[CHAT_CHECK].clear,
})

export const chatCreate = payload => ({
  type: actionTypes[CHAT_CREATE].pending,
  payload,
})

export const chatList = payload => ({
  type: actionTypes[CHAT_LIST].pending,
  payload,
})

export const chatListClear = () => ({
  type: actionTypes[CHAT_LIST].clear,
})

export const chatMessages = payload => ({
  type: actionTypes[CHAT_MESSAGES].pending,
  payload,
})

export const chatMessagesEdit = payload => ({
  type: actionTypes[CHAT_MESSAGES].edit,
  payload,
})

export const chatMessagesClear = () => ({
  type: actionTypes[CHAT_MESSAGES].clear,
})
