import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'
import actionTypes, { CITY_SEARCH, CITY_ALL_SHORT, CITY_REGIONS } from './actionTypes'
import * as API from '../../constants/api'
import BarakaApi from '../../helpers/ajax'

const api = new BarakaApi()

const searchCityEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[CITY_SEARCH].pending),
    mergeMap(({ payload }) =>
      api.post(API.CITIES_SEARCH_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[CITY_SEARCH].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[CITY_SEARCH].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getCityAllShortEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[CITY_ALL_SHORT].pending),
    mergeMap(({ payload }) =>
      api.get(API.CITY_ALL_SHORT_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[CITY_ALL_SHORT].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[CITY_ALL_SHORT].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getCityRegionsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[CITY_REGIONS].pending),
    mergeMap(({ payload }) =>
      api.get(API.CITIES_REGIONS_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[CITY_REGIONS].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[CITY_REGIONS].rejected,
            payload: response,
          })
        )
      )
    )
  )

export default combineEpics(searchCityEpic, getCityAllShortEpic, getCityRegionsEpic)
