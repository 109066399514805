import createReducer from './createReducer'

export const defaultState = {
  data: null,
  error: null,
  loading: false,
  failed: false,
}

const PENDING = '_PENDING'
const FULFILLED = '_FULFILLED'
const EDIT = '_EDIT'
const REJECTED = '_REJECTED'
const CLEAR = '_CLEAR'

export const actionPending = actionName => `${actionName}${PENDING}`
export const actionFulfilled = actionName => `${actionName}${FULFILLED}`
export const actionEdit = actionName => `${actionName}${EDIT}`
export const actionRejected = actionName => `${actionName}${REJECTED}`
export const actionClear = actionName => `${actionName}${CLEAR}`

const createState = actionName => {
  return createReducer(defaultState, {
    [actionPending(actionName)](state) {
      return {
        ...state,
        loading: true,
      }
    },
    [actionFulfilled(actionName)](state, action) {
      return {
        ...state,
        data: action.payload,
        error: null,
        loading: false,
        failed: false,
      }
    },
    [actionEdit(actionName)](state, action) {
      return {
        ...state,
        data: action.payload,
        error: null,
        loading: false,
        failed: false,
      }
    },
    [actionRejected(actionName)](state, action) {
      return {
        ...state,
        data: null,
        error: action.payload,
        loading: false,
        failed: true,
      }
    },
    [actionClear(actionName)]() {
      return defaultState
    },
  })
}

export default createState
