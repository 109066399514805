import createAction from '../../helpers/createAction'

export const ADVERT = 'ADVERT'
export const ADVERT_CREATE = 'ADVERT_CREATE'
export const ADVERT_EDIT = 'ADVERT_EDIT'
export const ADVERT_DELETE = 'ADVERT_DELETE'
export const ADVERT_ACTIVATE = 'ADVERT_ACTIVATE'
export const ADVERT_LIST = 'ADVERT_LIST'
export const ADVERT_FILTER_LIST = 'ADVERT_FILTER_LIST'
export const ADVERT_SUGGEST = 'ADVERT_SUGGEST'
export const ADVERT_SIMILAR = 'ADVERT_SIMILAR'
export const ADVERT_USER = 'ADVERT_USER'
export const ADVERT_PHONE = 'ADVERT_PHONE'
export const ADVERT_MESSAGE_CREATE = 'ADVERT_MESSAGE_CREATE'
export const ADVERT_COMPLAIN_CREATE = 'ADVERT_COMPLAIN_CREATE'
export const ADVERT_COMPLAIN_LIST = 'ADVERT_COMPLAIN_LIST'

export default {
  [ADVERT]: createAction(ADVERT),
  [ADVERT_CREATE]: createAction(ADVERT_CREATE),
  [ADVERT_EDIT]: createAction(ADVERT_EDIT),
  [ADVERT_DELETE]: createAction(ADVERT_DELETE),
  [ADVERT_ACTIVATE]: createAction(ADVERT_ACTIVATE),
  [ADVERT_LIST]: createAction(ADVERT_LIST),
  [ADVERT_FILTER_LIST]: createAction(ADVERT_FILTER_LIST),
  [ADVERT_SUGGEST]: createAction(ADVERT_SUGGEST),
  [ADVERT_SIMILAR]: createAction(ADVERT_SIMILAR),
  [ADVERT_USER]: createAction(ADVERT_USER),
  [ADVERT_PHONE]: createAction(ADVERT_PHONE),
  [ADVERT_MESSAGE_CREATE]: createAction(ADVERT_MESSAGE_CREATE),
  [ADVERT_COMPLAIN_CREATE]: createAction(ADVERT_COMPLAIN_CREATE),
  [ADVERT_COMPLAIN_LIST]: createAction(ADVERT_COMPLAIN_LIST),
}
