import actionTypes, {
  STORE,
  STORE_NAME,
  STORE_CREATE,
  STORE_UPDATE,
  STORE_DELETE,
  STORE_LIST,
  STORE_FILTER_LIST,
  STORE_LIST_WEB,
} from './actionTypes'

export const store = payload => ({
  type: actionTypes[STORE].pending,
  payload,
})

export const storeClear = () => ({
  type: actionTypes[STORE].clear,
})

export const storeName = payload => ({
  type: actionTypes[STORE_NAME].pending,
  payload,
})

export const storeNameClear = () => ({
  type: actionTypes[STORE_NAME].clear,
})

export const storeCreate = payload => ({
  type: actionTypes[STORE_CREATE].pending,
  payload,
})

export const storeUpdate = payload => ({
  type: actionTypes[STORE_UPDATE].pending,
  payload,
})

export const storeDelete = payload => ({
  type: actionTypes[STORE_DELETE].pending,
  payload,
})

export const storeList = payload => ({
  type: actionTypes[STORE_LIST].pending,
  payload,
})

export const storeListClear = () => ({
  type: actionTypes[STORE_LIST].clear,
})

export const storeFilterList = payload => ({
  type: actionTypes[STORE_FILTER_LIST].pending,
  payload,
})

export const storeFilterListClear = () => ({
  type: actionTypes[STORE_FILTER_LIST].clear,
})

export const storeListWeb = payload => ({
  type: actionTypes[STORE_LIST_WEB].pending,
  payload,
})

export const storeListWebClear = () => ({
  type: actionTypes[STORE_LIST_WEB].clear,
})
