import {
  equals,
  map,
  mergeDeepRight,
  path,
  pipe,
  prop,
  propOr,
  replace,
  reverse,
  toLower,
} from 'ramda'
import { Responsive } from 'semantic-ui-react'
import {
  MAIN_PAGE_MAIN_CATEGORIES,
  MAIN_PAGE_TOP_SLIDER,
  TOP_NAV_BAR_CATEGORIES,
  STORE_MAIN_PAGE_MAIN_CATEGORIES,
  STORE_MAIN_PAGE_TOP_SLIDER,
  STORE_TOP_NAV_BAR_CATEGORIES,
} from '../constants/PageCategoryPositionTypes'

export const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

export const getWindowSize = windowSize => {
  const windowSizeWidth = prop('width', windowSize)
  const maxSizeWidthMobile = Responsive.onlyMobile.maxWidth
  const minSizeWidthTablet = Responsive.onlyTablet.minWidth
  const maxSizeWidthTablet = Responsive.onlyTablet.maxWidth
  const minSizeWidthComputer = Responsive.onlyComputer.minWidth
  const maxSizeWidthComputer = Responsive.onlyComputer.maxWidth
  const minSizeWidthLargeScreen = Responsive.onlyLargeScreen.minWidth
  const isMaxWidthMobile = maxSizeWidthMobile < windowSizeWidth
  const isMinWidthTablet = minSizeWidthTablet < windowSizeWidth
  const isMaxWidthTablet = maxSizeWidthTablet < windowSizeWidth
  const isMinWidthComputer = minSizeWidthComputer < windowSizeWidth
  const isMaxWidthComputer = maxSizeWidthComputer < windowSizeWidth
  const isMinWidthSizeLargeScreen = minSizeWidthLargeScreen < windowSizeWidth

  return {
    windowSize,
    windowSizeWidth,
    isMaxWidthMobile,
    isMinWidthTablet,
    isMaxWidthTablet,
    isMinWidthComputer,
    isMaxWidthComputer,
    isMinWidthSizeLargeScreen,
  }
}

export const getList = pipe(response =>
  mergeDeepRight(
    {
      page: 0,
      size: 20,
    },
    response
  )
)

export const getPrice = (price, priceType) =>
  pipe(replace(/ /g, ''), item =>
    equals(priceType, 'USD') ? replace(/y.e/g, '', item) : replace(/сум/g, '', item)
  )(price)

export const getStore = (name, type) => store => ({
  data: path([type, name, 'data'], store),
  loading: path([type, name, 'loading'], store),
  error: path([type, name, 'error'], store),
  failed: path([type, name, 'failed'], store),
})
export const getStoreForm = name => path(['form', name, 'values'])

export const getStoreFormExt = name => store => ({
  values: path(['form', name, 'values'], store),
  initial: path(['form', name, 'initial'], store),
  submitFailed: path(['form', name, 'submitFailed'], store),
})

export const getListStore = list => ({
  content: propOr([], 'content', list),
  topContent: propOr([], 'top_content', list),
  size: prop('size', list),
  totalElements: prop('total_elements', list),
  totalPages: prop('total_pages', list) || calculateTotalPagesByCount(list),
  last: prop('last', list),
})

const calculateTotalPagesByCount = list => {
  const pageable = prop('pageable', list)
  const pageSize = prop('pageSize', pageable)
  const totalElements = prop('total_elements', list)
  return Math.ceil(totalElements / pageSize)
}

export const getCategory = data => ({
  mainCategories: propOr([], MAIN_PAGE_MAIN_CATEGORIES, data),
  topSlider: propOr([], MAIN_PAGE_TOP_SLIDER, data),
  barCategories: propOr([], TOP_NAV_BAR_CATEGORIES, data),
  storeMainCategories: propOr([], STORE_MAIN_PAGE_MAIN_CATEGORIES, data),
  storeTopSlider: propOr([], STORE_MAIN_PAGE_TOP_SLIDER, data),
  storeBarCategories: propOr([], STORE_TOP_NAV_BAR_CATEGORIES, data),
})

export const getChatMessagesContent = pipe(
  propOr([], 'content'),
  reverse,
  map(item => ({
    author: prop('mine', item) ? 'me' : 'them',
    type: toLower(prop('message_type', item) || ''),
    data: {
      text: prop('message', item),
    },
  }))
)

export function getJsonFromQuery(query) {
  const filtersQuery = prop('field_filters', query)

  const filtersQueryJson = (filtersQuery && JSON.parse(decodeURIComponent(filtersQuery))) || {}
  const fieldFilters = []

  Object.keys(filtersQueryJson).map(function (key) {
    const item = filtersQueryJson[key]
    const jsonObject = JSON.parse(item)
    jsonObject['name'] = key
    fieldFilters.push(jsonObject)
  })

  return fieldFilters
}
