import { combineReducers } from 'redux'
import createState from '../../helpers/createState'
import { SUBCATEGORY, SUBCATEGORY_LIST } from './actionTypes'
import * as STATE from './state'

const reducer = combineReducers({
  [STATE.SUBCATEGORY_ITEM]: createState(SUBCATEGORY),
  [STATE.SUBCATEGORY_LIST]: createState(SUBCATEGORY_LIST),
})

export default reducer
