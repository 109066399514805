import createAction from '../../helpers/createAction'

export const STORE = 'STORE'
export const STORE_NAME = 'STORE_NAME'
export const STORE_CREATE = 'STORE_CREATE'
export const STORE_UPDATE = 'STORE_UPDATE'
export const STORE_DELETE = 'STORE_DELETE'
export const STORE_LIST = 'STORE_LIST'
export const STORE_FILTER_LIST = 'STORE_FILTER_LIST'
export const STORE_LIST_WEB = 'STORE_LIST_WEB'

export default {
  [STORE]: createAction(STORE),
  [STORE_NAME]: createAction(STORE_NAME),
  [STORE_CREATE]: createAction(STORE_CREATE),
  [STORE_UPDATE]: createAction(STORE_UPDATE),
  [STORE_DELETE]: createAction(STORE_DELETE),
  [STORE_LIST]: createAction(STORE_LIST),
  [STORE_FILTER_LIST]: createAction(STORE_FILTER_LIST),
  [STORE_LIST_WEB]: createAction(STORE_LIST_WEB),
}
