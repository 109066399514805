import createAction from '../../helpers/createAction'

const SUBCATEGORY = 'SUBCATEGORY'
const SUBCATEGORY_LIST = 'SUBCATEGORY_LIST'

export default {
  [SUBCATEGORY]: createAction(SUBCATEGORY),
  [SUBCATEGORY_LIST]: createAction(SUBCATEGORY_LIST),
}

export { SUBCATEGORY, SUBCATEGORY_LIST }
