import { prop, dissoc } from 'ramda'
import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'
import actionTypes, { CHAT_CHECK, CHAT_CREATE, CHAT_LIST, CHAT_MESSAGES } from './actionTypes'
import * as API from '../../constants/api'
import BarakaApi from '../../helpers/ajax'
import * as actions from '../../reducers/actions'

const api = new BarakaApi()

const checkChatEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[CHAT_CHECK].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.WEB_CHAT_CHECK_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[CHAT_CHECK].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[CHAT_CHECK].rejected,
            payload: response,
          })
        )
      )
    )
  )

const createChatEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[CHAT_CREATE].pending),
    mergeMap(({ payload }) =>
      api.securedPost(API.WEB_CHAT_CREATE_URL, dissoc('text', payload)).pipe(
        map(({ response }) => ({
          type: actionTypes[CHAT_CREATE].fulfilled,
          payload: response,
          args: payload,
        })),
        catchError(response =>
          of({
            type: actionTypes[CHAT_CREATE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const createChatFulfilledEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[CHAT_CREATE].fulfilled),
    map(({ payload }) =>
      actions.chatCheckEdit({
        ...payload,
        chat_id: prop('chat_id', payload),
        exist: true,
      })
    )
  )

const createChatFulfilledSubscribeEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[CHAT_CREATE].fulfilled),
    map(({ payload, args }) =>
      actions.socketUserQueueMessages({
        ...payload,
        text: prop('text', args),
        chat_id: prop('chat_id', payload),
      })
    )
  )

const getChatListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[CHAT_LIST].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.WEB_CHAT_LIST_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[CHAT_LIST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[CHAT_LIST].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getChatMessagesEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[CHAT_MESSAGES].pending),
    mergeMap(({ payload }) =>
      api.securedGet(API.WEB_CHAT_MESSAGES_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[CHAT_MESSAGES].fulfilled,
          payload: response,
          args: payload,
        })),
        catchError(response =>
          of({
            type: actionTypes[CHAT_MESSAGES].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getChatMessagesFulfilledEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[CHAT_MESSAGES].fulfilled),
    map(({ args }) => actions.socketUserQueueMessages(args))
  )

export default combineEpics(
  checkChatEpic,
  createChatEpic,
  createChatFulfilledEpic,
  createChatFulfilledSubscribeEpic,
  getChatListEpic,
  getChatMessagesEpic,
  getChatMessagesFulfilledEpic
)
