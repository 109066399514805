import { ofType, combineEpics } from 'redux-observable'
import actionTypes, { WEB_PAGE_SITE_MAP_INFO } from '../siteMap/actionTypes'

import { catchError, map, mergeMap } from 'rxjs/operators'
import * as API from '../../constants/api'
import { of } from 'rxjs'
import BarakaApi from '../../helpers/ajax'

const api = new BarakaApi()

const getSiteMapInfoEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[WEB_PAGE_SITE_MAP_INFO].pending),
    mergeMap(({ payload }) =>
      api.get(API.WEB_PAGE_SITE_MAP_INFO_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[WEB_PAGE_SITE_MAP_INFO].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[WEB_PAGE_SITE_MAP_INFO].rejected,
            payload: response,
          })
        )
      )
    )
  )

export default combineEpics(getSiteMapInfoEpic)
