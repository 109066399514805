import { prop, dissoc } from 'ramda'
import { combineEpics, ofType } from 'redux-observable'
import { of, iif } from 'rxjs'
import { mergeMap, map, catchError, mapTo } from 'rxjs/operators'
import { push } from 'connected-react-router'
import actionTypes, {
  TOKEN,
  SIGN_IN,
  SIGN_UP,
  SIGN_UP_PHONE,
  SIGN_UP_REGISTER,
  USER_INFO,
  MAIN_INFO,
} from './actionTypes'
import * as API from '../../constants/api'
import * as ROUTE from '../../constants/routes'
import BarakaApi from '../../helpers/ajax'
import { toastrError } from '../../helpers/toastr'

const api = new BarakaApi()

const signInEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[SIGN_IN].pending),
    mergeMap(({ payload }) =>
      api.post(API.SIGNIN_URL, null, payload).pipe(
        mergeMap(({ response }) => {
          return [
            {
              type: actionTypes[SIGN_IN].fulfilled,
              payload: prop('token', response),
              registered: !prop('registered', response),
            },
            {
              type: actionTypes[USER_INFO].fulfilled,
              payload: prop('user_info', response),
            },
            {
              type: TOKEN,
              payload: prop('token', response),
            },
          ]
        }),
        catchError(({ response }) =>
          of({
            type: actionTypes[USER_INFO].rejected,
            payload: response,
          })
        )
      )
    )
  )

const signInFulfilledEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[SIGN_IN].fulfilled),
    mergeMap(({ registered }) =>
      iif(() => registered, of(push(ROUTE.AUTH_SIGNUP)), of(push(ROUTE.MAIN)))
    )
  )

const signInRejectedEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[SIGN_IN].rejected),
    map(({ payload: { response } }) =>
      toastrError({
        id: Math.floor(Math.random() * 1000),
        title: prop('error', response),
        message: prop('message', response),
      })
    )
  )

const signUpEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[SIGN_UP].pending),
    mergeMap(({ payload }) =>
      api.post(API.SIGNUP_URL, null, payload).pipe(
        mergeMap(({ response }) => [
          {
            type: actionTypes[SIGN_UP].fulfilled,
            payload: response,
          },
          {
            type: actionTypes[USER_INFO].fulfilled,
            payload: prop('user_info', response),
          },
          {
            type: TOKEN,
            payload: prop('token', response),
          },
        ]),
        catchError(({ response }) =>
          of({
            type: actionTypes[USER_INFO].rejected,
            payload: response,
          })
        )
      )
    )
  )

const signUpPhoneEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[SIGN_UP_PHONE].pending),
    mergeMap(({ payload }) => {
      console.log('payload', payload)
      return api.post(API.SIGN_UP_PHONE, null, payload).pipe(
        mergeMap(({ response }) => [
          {
            type: actionTypes[SIGN_UP_PHONE].fulfilled,
            payload: response,
          },
          // {
          //   type: actionTypes[USER_INFO].fulfilled,
          //   payload: prop('user_info', response),
          // },
          // {
          //   type: TOKEN,
          //   payload: prop('token', response),
          // },
        ]),
        catchError(({ response }) => {
          console.log('rejected', response)
          return of({
            type: actionTypes[USER_INFO].rejected,
            payload: response,
          })
        })
      )
    })
  )

const signUpFulfilledEpic = action$ =>
  action$.pipe(ofType(actionTypes[SIGN_UP].fulfilled), mapTo(push(ROUTE.AUTH_SIGNUP)))

const signUpRegisterEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[SIGN_UP_REGISTER].pending),
    mergeMap(({ payload }) =>
      api.securedPut(API.PROFILE_UPDATE_URL, null, payload).pipe(
        mergeMap(({ response }) => [
          {
            type: actionTypes[USER_INFO].fulfilled,
            payload: prop('user_info', response),
          },
          {
            type: actionTypes[SIGN_UP_REGISTER].fulfilled,
            payload: response,
          },
        ]),
        catchError(({ response }) =>
          of({
            type: actionTypes[SIGN_UP_REGISTER].rejected,
            payload: response,
          })
        )
      )
    )
  )

const signUpRegisterFulfilledEpic = action$ =>
  action$.pipe(ofType(actionTypes[SIGN_UP_REGISTER].fulfilled), mapTo(push(ROUTE.MAIN)))

const signUpRegisterErrorEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[SIGN_UP_REGISTER].rejected),
    map(({ payload }) =>
      toastrError({
        id: Math.floor(Math.random() * 1000),
        title: prop('error', payload),
        message: prop('message', payload),
      })
    )
  )

const userInfoEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[USER_INFO].pending),
    mergeMap(({ payload }) => {
      const secure = prop('secure', payload)
      const infoApi = secure ? api.securedGet : api.get

      return infoApi(API.USER_ME_INFO_URL, dissoc('secure', payload)).pipe(
        map(({ response }) => {
          return {
            type: actionTypes[USER_INFO].fulfilled,
            payload: response,
          }
        }),
        catchError(({ response }) =>
          of({
            type: actionTypes[USER_INFO].rejected,
            payload: response,
          })
        )
      )
    })
  )

const mainInfoEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[MAIN_INFO].pending),
    mergeMap(({ payload }) => {
      const secure = prop('secure', payload)
      const infoApi = secure ? api.securedGet : api.get

      return infoApi(API.MAIN_INFO_URL, dissoc('secure', payload)).pipe(
        map(({ response }) => {
          return {
            type: actionTypes[MAIN_INFO].fulfilled,
            payload: response,
          }
        }),
        catchError(response =>
          of({
            type: actionTypes[MAIN_INFO].rejected,
            payload: response,
          })
        )
      )
    })
  )

export default combineEpics(
  signInEpic,
  signInFulfilledEpic,
  signInRejectedEpic,
  signUpEpic,
  signUpPhoneEpic,
  signUpFulfilledEpic,
  signUpRegisterEpic,
  signUpRegisterFulfilledEpic,
  signUpRegisterErrorEpic,
  userInfoEpic,
  mainInfoEpic
)
