import { LANGUAGE, REGION, CITY, DISTRICT } from './actionTypes'

export const settingsLanguage = payload => ({
  type: LANGUAGE,
  payload,
})

export const settingsRegion = payload => ({
  type: REGION,
  payload,
})

export const settingsCity = payload => ({
  type: CITY,
  payload,
})

export const settingDistrict = payload => ({
  type: DISTRICT,
  payload,
})
