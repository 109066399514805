// module.exports = {
//   'general.copyright': 'Baraka © 2020 Barcha huquqlar himoyalangan.',
//   'general.location': 'Joylashuv',
//   'general.byCBUCurse': 'O\'zR MB kursi bo\'yicha',
// }

export default {
  home: 'asosiy',
  english: 'inglizcha',
  russian: 'ruscha',
  uzbek: "o'zbekcha",
  adverts: "e'lonlar",
  shops: "do'konlar",
  messages: 'xabarlar',
  'choose-a-place': 'joy tanlang',
  'post-add-title': "Baraka-da e'lon joylashtirish",
  search: 'qidirish',
  'search-placeholder': 'nima izlamoqdasiz...',
  'searching-results': 'qidiruv natijalari',
  'create-product': "e'lon qo'shish ",
  'choose-category': 'kategoriyani tanlang',
  'my-adverts': "mening e'lonlarim",
  'my-messages': 'mening xabarlarim',
  'general.copyright': 'Baraka © 2020 Все права защищены.',
  'general.location': 'Местоположение',
  'general.district': 'Район',
  'general.city': 'Город',
  'general.byCBUCurse': 'по курсу ЦБ РУз',

  //STORE
  contacts: 'Контакты',
  products: 'Объявления',
  feedbacks: 'Отзывы',
  aboutCompany: 'О Компании',

  //Nav Bar
  chooseRegion: 'Выберите регион',
  'navbar.products': 'Объявления',
  'navbar.stores': 'Магазины',
  'navbar.messages': 'Сообщения',
  'navbar.favorites': 'Избранное',

  myproducts: 'Мои объявления',

  //Main Page
  chooseCategory: 'Выберите категорию',
  allProducts: 'Barcha e`lonlar',
  showMore: 'Показать все ',
  showLess: 'Скрыть ',

  //Filters
  chooseSorting: 'Выберите сортировку',
  priceSum: 'Цена, сум',
  applyFilters: 'Применить',
  resetFilters: 'Сбросить все фильтры',
  smsVerificationError: `SMS kodi noto\'g\'ri`,
  signUpViaPhone: "Telefon orqali ro'yxatdan o'tish",
}
