import { prop, dissoc } from 'ramda'
import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'
import actionTypes, { SUBCATEGORY, SUBCATEGORY_LIST } from './actionTypes'
import * as API from '../../constants/api'
import BarakaApi from '../../helpers/ajax'

const api = new BarakaApi()

const getSubcategoryEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[SUBCATEGORY].pending),
    mergeMap(({ payload }) =>
      api.securedGet(`${API.SUBCATEGORY_URL}${prop('id', payload)}`, dissoc('id', payload)).pipe(
        map(({ response }) => ({
          type: actionTypes[SUBCATEGORY].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[SUBCATEGORY].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getSubcategoryListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[SUBCATEGORY_LIST].pending),
    mergeMap(({ payload }) =>
      api.get(API.RUBRIC_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[SUBCATEGORY_LIST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[SUBCATEGORY_LIST].rejected,
            payload: response,
          })
        )
      )
    )
  )

export default combineEpics(getSubcategoryEpic, getSubcategoryListEpic)
