import actionTypes, {
  ADVERT,
  ADVERT_CREATE,
  ADVERT_EDIT,
  ADVERT_DELETE,
  ADVERT_LIST,
  ADVERT_FILTER_LIST,
  ADVERT_SUGGEST,
  ADVERT_SIMILAR,
  ADVERT_USER,
  ADVERT_PHONE,
  ADVERT_MESSAGE_CREATE,
  ADVERT_COMPLAIN_CREATE,
  ADVERT_COMPLAIN_LIST,
  ADVERT_ACTIVATE,
} from './actionTypes'

export const advert = payload => ({
  type: actionTypes[ADVERT].pending,
  payload,
})

export const advertClear = () => ({
  type: actionTypes[ADVERT].clear,
})

export const advertCreate = payload => ({
  type: actionTypes[ADVERT_CREATE].pending,
  payload,
})

export const advertEdit = payload => ({
  type: actionTypes[ADVERT_EDIT].pending,
  payload,
})

export const advertEditClear = () => ({
  type: actionTypes[ADVERT_EDIT].clear,
})

export const advertDelete = payload => ({
  type: actionTypes[ADVERT_DELETE].pending,
  payload,
})

export const advertActivate = payload => ({
  type: actionTypes[ADVERT_ACTIVATE].pending,
  payload,
})

export const advertList = payload => ({
  type: actionTypes[ADVERT_LIST].pending,
  payload,
})

export const advertListClear = () => ({
  type: actionTypes[ADVERT_LIST].clear,
})

export const advertFilterList = payload => ({
  type: actionTypes[ADVERT_FILTER_LIST].pending,
  payload,
})

export const advertFilterListClear = () => ({
  type: actionTypes[ADVERT_FILTER_LIST].clear,
})

export const advertSuggest = payload => ({
  type: actionTypes[ADVERT_SUGGEST].pending,
  payload,
})

export const advertSuggestClear = () => ({
  type: actionTypes[ADVERT_SUGGEST].clear,
})

export const advertSimilar = payload => ({
  type: actionTypes[ADVERT_SIMILAR].pending,
  payload,
})

export const advertSimilarClear = () => ({
  type: actionTypes[ADVERT_SIMILAR].clear,
})

export const advertUser = payload => ({
  type: actionTypes[ADVERT_USER].pending,
  payload,
})

export const advertUserClear = () => ({
  type: actionTypes[ADVERT_USER].clear,
})

export const advertPhone = payload => ({
  type: actionTypes[ADVERT_PHONE].pending,
  payload,
})

export const advertMessageCreate = payload => ({
  type: actionTypes[ADVERT_MESSAGE_CREATE].pending,
  payload,
})

export const advertComplainCreate = payload => ({
  type: actionTypes[ADVERT_COMPLAIN_CREATE].pending,
  payload,
})

export const advertComplainList = payload => ({
  type: actionTypes[ADVERT_COMPLAIN_LIST].pending,
  payload,
})

export const advertComplainListClear = () => ({
  type: actionTypes[ADVERT_COMPLAIN_LIST].clear,
})
