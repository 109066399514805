import createAction from '../../helpers/createAction'

export const CITY_SEARCH = 'CITY_SEARCH'
export const CITY_ALL_SHORT = 'CITY_ALL_SHORT'
export const CITY_REGIONS = 'CITY_REGIONS'

export default {
  [CITY_SEARCH]: createAction(CITY_SEARCH),
  [CITY_ALL_SHORT]: createAction(CITY_ALL_SHORT),
  [CITY_REGIONS]: createAction(CITY_REGIONS),
}
