import { dissoc, prop } from 'ramda'
import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError, mapTo } from 'rxjs/operators'
import { push } from 'connected-react-router'
import actionTypes, {
  STORE,
  STORE_NAME,
  STORE_CREATE,
  STORE_UPDATE,
  STORE_DELETE,
  STORE_LIST,
  STORE_FILTER_LIST,
  STORE_LIST_WEB,
} from './actionTypes'
import * as API from '../../constants/api'
import * as ROUTE from '../../constants/routes'
import BarakaApi from '../../helpers/ajax'
import { toastrError } from '../../helpers/toastr'

const api = new BarakaApi()

const getStoreEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[STORE].pending),
    mergeMap(({ payload }) =>
      api.get(`${API.STORE_URL}${prop('id', payload)}`).pipe(
        map(({ response }) => ({
          type: actionTypes[STORE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[STORE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getStoreNameEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[STORE_NAME].pending),
    mergeMap(({ payload }) =>
      api.securedGet(`${API.WEB_STORE_NAME_URL}${prop('name', payload)}`).pipe(
        map(({ response }) => ({
          type: actionTypes[STORE_NAME].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[STORE_NAME].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getStoreNameRejectedEpic = action$ =>
  action$.pipe(ofType(actionTypes[STORE_NAME].rejected), mapTo(push(ROUTE.MAIN_STORES)))

const getStoreNameRejectedMessageEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[STORE_NAME].rejected),
    map(({ payload: { response } }) =>
      toastrError({
        id: Math.floor(Math.random() * 1000),
        title: prop('error', response),
        message: prop('message', response),
      })
    )
  )

const createStoreEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[STORE_CREATE].pending),
    mergeMap(({ payload }) =>
      api.securedPost(API.STORE_URL, null, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[STORE_CREATE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[STORE_CREATE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const createStoreFulfilledEpic = action$ =>
  action$.pipe(ofType(actionTypes[STORE_CREATE].fulfilled), mapTo(push(ROUTE.MAIN_STORES)))

const createStoreErrorEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[STORE_CREATE].rejected),
    map(({ payload: { response } }) =>
      toastrError({
        id: Math.floor(Math.random() * 1000),
        title: prop('error', response),
        message: prop('message', response),
      })
    )
  )

const updateStoreEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[STORE_UPDATE].pending),
    mergeMap(({ payload }) =>
      api.securedPut(`${API.STORE_URL}${prop('id', payload)}`, null, dissoc('id', payload)).pipe(
        map(({ response }) => ({
          type: actionTypes[STORE_UPDATE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[STORE_UPDATE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const updateStoreFulfilledEpic = action$ =>
  action$.pipe(ofType(actionTypes[STORE_UPDATE].fulfilled), mapTo(push(ROUTE.MAIN_STORES)))

const deleteStoreEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[STORE_DELETE].pending),
    mergeMap(({ payload }) =>
      api.securedDelete(`${API.ADVERT_MAIN_DELETE_URL}${prop('id', payload)}`).pipe(
        map(({ response }) => ({
          type: actionTypes[STORE_DELETE].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[STORE_DELETE].rejected,
            payload: response,
          })
        )
      )
    )
  )

const deleteStoreFulfilledEpic = action$ =>
  action$.pipe(ofType(actionTypes[STORE_DELETE].fulfilled), mapTo(push(ROUTE.USER_STORE_LIST)))

const getStoreListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[STORE_LIST].pending),
    mergeMap(({ payload }) =>
      api.get(API.STORE_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[STORE_LIST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[STORE_LIST].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getStoreFilterListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[STORE_FILTER_LIST].pending),
    mergeMap(({ payload }) =>
      api.get(API.STORE_FILTER_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[STORE_FILTER_LIST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[STORE_FILTER_LIST].rejected,
            payload: response,
          })
        )
      )
    )
  )

const getStoreListWebEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[STORE_LIST_WEB].pending),
    mergeMap(({ payload }) =>
      api.get(API.WEB_STORE_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[STORE_LIST_WEB].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[STORE_LIST_WEB].rejected,
            payload: response,
          })
        )
      )
    )
  )

export default combineEpics(
  getStoreEpic,
  getStoreNameEpic,
  getStoreNameRejectedEpic,
  getStoreNameRejectedMessageEpic,
  createStoreEpic,
  createStoreFulfilledEpic,
  createStoreErrorEpic,
  updateStoreEpic,
  updateStoreFulfilledEpic,
  deleteStoreEpic,
  deleteStoreFulfilledEpic,
  getStoreListEpic,
  getStoreFilterListEpic,
  getStoreListWebEpic
)
