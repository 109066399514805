import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import settings from './settings/reducer'
import auth from './auth/reducers'
import rubric from './rubric/reducers'
import category from './category/reducers'
import pageCategory from './pageCategory/reducers'
import subcategory from './subcategory/reducers'
import advert from './advert/reducers'
import store from './store/reducers'
import user from './user/reducer'
import regions from './regions/reducers'
import socket from './socket/reducer'
import siteMap from './siteMap/reducers'
import { appReducer } from './appReducer'

const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    toastr: toastrReducer,
    settings,
    auth,
    rubric,
    category,
    pageCategory,
    subcategory,
    advert,
    store,
    user,
    regions,
    socket,
    siteMap,
    app: appReducer,
  })

export default reducers
