import { combineReducers } from 'redux'
import createReducer from '../../helpers/createReducer'
import createState from '../../helpers/createState'
import { TOKEN, TOKEN_CLEAR, USER_INFO, MAIN_INFO, SIGN_UP_PHONE } from './actionTypes'
import * as STATE from './state'
import { setToken } from '../../helpers/tokenStorage'

const reducer = combineReducers({
  [STATE.AUTH_TOKEN]: createReducer(
    {},
    {
      [TOKEN](state, action) {
        setToken(action.payload, false)
        return {
          ...state,
          data: action.payload,
        }
      },
      [TOKEN_CLEAR](state) {
        return {
          ...state,
          data: undefined,
        }
      },
    }
  ),
  [STATE.REGISTRATION_INFO]: createState(SIGN_UP_PHONE),
  [STATE.AUTH_INFO]: createState(USER_INFO),
  [STATE.MAIN_INFO]: createState(MAIN_INFO),
})

export default reducer
