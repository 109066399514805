import { DISTRICT } from '../constants/storage'

export const getStorage = local => (local ? sessionStorage : localStorage)

export const getDistrict = () => localStorage.getItem(DISTRICT) || sessionStorage.getItem(DISTRICT)

export const setDistrict = (district, local = false) => {
  const storage = getStorage(local)

  storage.setItem(DISTRICT, district)
}

export const removeDistrict = () => {
  localStorage.removeItem(DISTRICT)
  sessionStorage.removeItem(DISTRICT)
}
