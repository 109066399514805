import actionTypes, {
  USER_PROFILE_UPDATE,
  USER_PRODUCTS_ACTIVE,
  USER_PRODUCTS_COUNT,
  USER_PRODUCTS_DELETED,
  USER_STORES_ACTIVE,
  USER_STORES_COUNT,
  USER_STORES_DELETED,
  USER_STORE_NAME_CHECK,
  USER_STORE_EDIT,
  USER_STORE_ARCHIVE,
  USER_STORE_UNARCHIVE,
  USER_MESSAGES_ACTIVE,
  USER_MESSAGES_COUNT,
  USER_MESSAGES_DELETED,
  USER_PROMOTION_PRODUCT_INFO,
  USER_PROMOTION_STORE_INFO,
  PAYMENT_GENERATE,
  ACCOUNT_ADVERT_SUBSCRIBE,
  ACCOUNT_STORE_SUBSCRIBE,
  ACCOUNT_BALANCE,
  USER_ADVERT_FAVORITE,
  USER_ADVERT_FAVORITE_DELETE,
  USER_ADVERT_FAVORITE_LIST,
  USER_STORE_FAVORITE,
  USER_STORE_FAVORITE_DELETE,
  USER_STORE_FAVORITE_LIST,
  USER_REVIEW_EDIT,
  USER_REVIEW_DELETE,
  USER_REVIEW_LIST,
} from './actionTypes'

export const userProfileUpdate = payload => ({
  type: actionTypes[USER_PROFILE_UPDATE].pending,
  payload,
})

export const userProductsActive = payload => ({
  type: actionTypes[USER_PRODUCTS_ACTIVE].pending,
  payload,
})

export const userProductsActiveClear = () => ({
  type: actionTypes[USER_PRODUCTS_ACTIVE].clear,
})

export const userProductsCount = payload => ({
  type: actionTypes[USER_PRODUCTS_COUNT].pending,
  payload,
})

export const userProductsCountClear = () => ({
  type: actionTypes[USER_PRODUCTS_COUNT].clear,
})

export const userProductsDeleted = payload => ({
  type: actionTypes[USER_PRODUCTS_DELETED].pending,
  payload,
})

export const userProductsDeletedClear = () => ({
  type: actionTypes[USER_PRODUCTS_DELETED].clear,
})

export const userStoresActive = payload => ({
  type: actionTypes[USER_STORES_ACTIVE].pending,
  payload,
})

export const userStoresActiveClear = () => ({
  type: actionTypes[USER_STORES_ACTIVE].clear,
})

export const userStoresCount = payload => ({
  type: actionTypes[USER_STORES_COUNT].pending,
  payload,
})

export const userStoresCountClear = () => ({
  type: actionTypes[USER_STORES_COUNT].clear,
})

export const userStoresDeleted = payload => ({
  type: actionTypes[USER_STORES_DELETED].pending,
  payload,
})

export const userStoresDeletedClear = () => ({
  type: actionTypes[USER_STORES_DELETED].clear,
})

export const userStoreNameCheck = payload => ({
  type: actionTypes[USER_STORE_NAME_CHECK].pending,
  payload,
})

export const userStoreNameCheckClear = () => ({
  type: actionTypes[USER_STORE_NAME_CHECK].clear,
})

export const userStoreEdit = payload => ({
  type: actionTypes[USER_STORE_EDIT].pending,
  payload,
})

export const userStoreArchive = payload => ({
  type: actionTypes[USER_STORE_ARCHIVE].pending,
  payload,
})

export const userStoreUnarchive = payload => ({
  type: actionTypes[USER_STORE_UNARCHIVE].pending,
  payload,
})

export const userMessagesActive = payload => ({
  type: actionTypes[USER_MESSAGES_ACTIVE].pending,
  payload,
})

export const userMessagesActiveClear = () => ({
  type: actionTypes[USER_MESSAGES_ACTIVE].clear,
})

export const userMessagesCount = payload => ({
  type: actionTypes[USER_MESSAGES_COUNT].pending,
  payload,
})

export const userMessagesCountClear = () => ({
  type: actionTypes[USER_MESSAGES_COUNT].clear,
})

export const userMessagesDeleted = payload => ({
  type: actionTypes[USER_MESSAGES_DELETED].pending,
  payload,
})

export const userMessagesDeletedClear = () => ({
  type: actionTypes[USER_MESSAGES_DELETED].clear,
})

export const userPromotionProductInfo = payload => ({
  type: actionTypes[USER_PROMOTION_PRODUCT_INFO].pending,
  payload,
})

export const userPromotionProductInfoClear = () => ({
  type: actionTypes[USER_PROMOTION_PRODUCT_INFO].clear,
})

export const userPromotionStoreInfo = payload => ({
  type: actionTypes[USER_PROMOTION_STORE_INFO].pending,
  payload,
})

export const userPromotionStoreInfoClear = () => ({
  type: actionTypes[USER_PROMOTION_STORE_INFO].clear,
})

export const paymentGenerate = payload => ({
  type: actionTypes[PAYMENT_GENERATE].pending,
  payload,
})

export const accountAdvertSubscribe = payload => ({
  type: actionTypes[ACCOUNT_ADVERT_SUBSCRIBE].pending,
  payload,
})

export const accountStoreSubscribe = payload => ({
  type: actionTypes[ACCOUNT_STORE_SUBSCRIBE].pending,
  payload,
})

export const accountBalance = payload => ({
  type: actionTypes[ACCOUNT_BALANCE].pending,
  payload,
})

export const accountBalanceClear = () => ({
  type: actionTypes[ACCOUNT_BALANCE].clear,
})

export const userAdvertFavorite = payload => ({
  type: actionTypes[USER_ADVERT_FAVORITE].pending,
  payload,
})

export const userAdvertFavoriteDelete = payload => ({
  type: actionTypes[USER_ADVERT_FAVORITE_DELETE].pending,
  payload,
})

export const userAdvertFavoriteList = payload => ({
  type: actionTypes[USER_ADVERT_FAVORITE_LIST].pending,
  payload,
})

export const userAdvertFavoriteListClear = () => ({
  type: actionTypes[USER_ADVERT_FAVORITE_LIST].clear,
})

export const userStoreFavorite = payload => ({
  type: actionTypes[USER_STORE_FAVORITE].pending,
  payload,
})

export const userStoreFavoriteDelete = payload => ({
  type: actionTypes[USER_STORE_FAVORITE_DELETE].pending,
  payload,
})

export const userStoreFavoriteList = payload => ({
  type: actionTypes[USER_STORE_FAVORITE_LIST].pending,
  payload,
})

export const userStoreFavoriteListClear = () => ({
  type: actionTypes[USER_STORE_FAVORITE_LIST].clear,
})

export const userReviewEdit = payload => ({
  type: actionTypes[USER_REVIEW_EDIT].pending,
  payload,
})

export const userReviewDelete = payload => ({
  type: actionTypes[USER_REVIEW_DELETE].pending,
  payload,
})

export const userReviewList = payload => ({
  type: actionTypes[USER_REVIEW_LIST].pending,
  payload,
})

export const userReviewListClear = () => ({
  type: actionTypes[USER_REVIEW_LIST].clear,
})
