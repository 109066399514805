import { combineEpics } from 'redux-observable'
import auth from './auth/epic'
import rubric from './rubric/epic'
import category from './category/epic'
import pageCategory from './pageCategory/epic'
import subcategory from './subcategory/epic'
import advert from './advert/epic'
import store from './store/epic'
import user from './user/epic'
import regions from './regions/epic'
import socket from './socket/epic'
import siteMap from './siteMap/epic'

export default combineEpics(
  auth,
  rubric,
  category,
  pageCategory,
  subcategory,
  advert,
  store,
  user,
  regions,
  socket,
  siteMap
)
