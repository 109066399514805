import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'
import actionTypes, { PAGE_CATEGORY_LIST } from './actionTypes'
import * as API from '../../constants/api'
import BarakaApi from '../../helpers/ajax'

const api = new BarakaApi()

const getPageCategoryListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes[PAGE_CATEGORY_LIST].pending),
    mergeMap(({ payload }) =>
      api.get(API.PAGE_CATEGORY_URL, payload).pipe(
        map(({ response }) => ({
          type: actionTypes[PAGE_CATEGORY_LIST].fulfilled,
          payload: response,
        })),
        catchError(response =>
          of({
            type: actionTypes[PAGE_CATEGORY_LIST].rejected,
            payload: response,
          })
        )
      )
    )
  )

export default combineEpics(getPageCategoryListEpic)
