import actionTypes, { PAGE_CATEGORY_LIST } from './actionTypes'

export const pageCategoryList = payload => ({
  type: actionTypes[PAGE_CATEGORY_LIST].pending,
  payload,
})

export const pageCategoryListClear = () => ({
  type: actionTypes[PAGE_CATEGORY_LIST].clear,
})
