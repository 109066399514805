import { combineReducers } from 'redux'
import createState from '../../helpers/createState'
import { STORE, STORE_NAME, STORE_LIST, STORE_FILTER_LIST, STORE_LIST_WEB } from './actionTypes'
import * as STATE from './state'

const reducers = combineReducers({
  [STATE.STORE_ITEM]: createState(STORE),
  [STATE.STORE_ITEM_NAME]: createState(STORE_NAME),
  [STATE.STORE_LIST]: createState(STORE_LIST),
  [STATE.STORE_FILTER_LIST]: createState(STORE_FILTER_LIST),
  [STATE.STORE_WEB_LIST]: createState(STORE_LIST_WEB),
})

export default reducers
