import { combineReducers } from 'redux'
import createState from '../../helpers/createState'
import { PAGE_CATEGORY_LIST } from './actionTypes'
import * as STATE from './state'

const reducer = combineReducers({
  [STATE.PAGE_CATEGORY_LIST]: createState(PAGE_CATEGORY_LIST),
})

export default reducer
