const NODE_ENV = process.env.NODE_ENV || 'development'

export const API_LOCAL = '192.168.100.32:9011'
export const API_LIVE = 'baraka.uz'
export const API_HOST = NODE_ENV !== 'development' ? API_LIVE : API_LIVE

export const API_PROJECT = 'baraka'
export const API_ROOT = 'api'
export const API_VERSION = 'v1'
export const API_URL = `https://${API_HOST}/${API_PROJECT}/${API_ROOT}/${API_VERSION}/`

export const API_LOCAL_WS = '192.168.100.32:9011'
export const API_LIVE_WS = 'baraka.uz'
export const API_HOST_WS = NODE_ENV !== 'development' ? API_LIVE_WS : API_LIVE_WS

export const SOCKET_ROOT = 'socket/messages'
export const SOCKET_URL = `wss://${API_HOST_WS}/${SOCKET_ROOT}`
export const SOCKET_SUBSCRIPTION_URL = '/topic/notification'
export const SOCKET_APP_SEND_MESSAGE = '/app/send.message'
export const SOCKET_USER_QUEUE_MESSAGES = chatId => `/user/queue/${chatId}.messages`

// Advert
export const ADVERT = 'product/'
export const ADVERT_URL = `${API_URL}${ADVERT}`
export const ADVERT_MAIN = `${ADVERT}main/`
export const ADVERT_MAIN_URL = `${API_URL}${ADVERT_MAIN}`
export const ADVERT_MAIN_ALL = `${ADVERT_MAIN}all/`
export const ADVERT_MAIN_ALL_URL = `${API_URL}${ADVERT_MAIN_ALL}`
export const ADVERT_MAIN_CREATE = `${ADVERT_MAIN}create/`
export const ADVERT_MAIN_CREATE_URL = `${API_URL}${ADVERT_MAIN_CREATE}`
export const ADVERT_MAIN_DELETE = `${ADVERT_MAIN}delete/`
export const ADVERT_MAIN_ACTIVATE = `${ADVERT_MAIN}activate/`
export const ADVERT_MAIN_DELETE_URL = `${API_URL}${ADVERT_MAIN_DELETE}`
export const ADVERT_MAIN_ACTIVATE_URL = `${API_URL}${ADVERT_MAIN_ACTIVATE}`
export const ADVERT_MAIN_EDIT = `${ADVERT_MAIN}edit/`
export const ADVERT_MAIN_EDIT_URL = `${API_URL}${ADVERT_MAIN_EDIT}`
export const ADVERT_MAIN_FILTER = `${ADVERT_MAIN}filter/`
export const ADVERT_MAIN_FILTER_URL = `${API_URL}${ADVERT_MAIN_FILTER}`
// export const ADVERT_MAIN_FILTER_ALL = `${ADVERT_MAIN_FILTER}all/`
export const ADVERT_MAIN_FILTER_ALL = `${ADVERT_MAIN_FILTER}fields/`
export const ADVERT_MAIN_FILTER_ALL_URL = `${API_URL}${ADVERT_MAIN_FILTER_ALL}`
export const ADVERT_MAIN_SUGGEST = `${ADVERT_MAIN}suggest/`
export const ADVERT_MAIN_SUGGEST_URL = `${API_URL}${ADVERT_MAIN_SUGGEST}`
export const ADVERT_MAIN_FULL_SUGGEST = `${ADVERT_MAIN}full/suggest/`
export const ADVERT_MAIN_FULL_SUGGEST_URL = `${API_URL}${ADVERT_MAIN_FULL_SUGGEST}`
export const ADVERT_MAIN_INDEXED = `${ADVERT_MAIN}indexed/`
export const ADVERT_MAIN_INDEXED_URL = `${API_URL}${ADVERT_MAIN_INDEXED}`
export const ADVERT_MAIN_INDEXED_ALL = `${ADVERT_MAIN_INDEXED}all/`
export const ADVERT_MAIN_INDEXED_ALL_URL = `${API_URL}${ADVERT_MAIN_INDEXED_ALL}`
export const ADVERT_MAIN_INITIAL_SUGGEST = `${ADVERT_MAIN}initial/suggest/`
export const ADVERT_MAIN_INITIAL_SUGGEST_URL = `${API_URL}${ADVERT_MAIN_INITIAL_SUGGEST}`
export const ADVERT_MAIN_SIMILAR = `${ADVERT_MAIN}similar/`
export const ADVERT_MAIN_SIMILAR_URL = `${API_URL}${ADVERT_MAIN_SIMILAR}`
export const ADVERT_MAIN_USER = `${ADVERT_MAIN}user/`
export const ADVERT_MAIN_USER_URL = `${API_URL}${ADVERT_MAIN_USER}`
export const ADVERT_RADIUS = `${ADVERT}radius/`
export const ADVERT_RADIUS_URL = `${API_URL}${ADVERT_RADIUS}`
export const ADVERT_COMPLAIN = `${ADVERT}complain/`
export const ADVERT_COMPLAIN_URL = `${API_URL}${ADVERT_COMPLAIN}`

// Product
export const PRODUCT = 'product/'
export const PRODUCT_URL = `${API_URL}${PRODUCT}`
export const PRODUCT_MESSAGE = `${PRODUCT}message/`
export const PRODUCT_MESSAGE_URL = `${API_URL}${PRODUCT_MESSAGE}`
export const PRODUCT_MESSAGE_ACTIVE = `${PRODUCT_MESSAGE}active/`
export const PRODUCT_MESSAGE_ACTIVE_URL = `${API_URL}${PRODUCT_MESSAGE_ACTIVE}`
export const PRODUCT_MESSAGE_ACTIVE_ALL = `${PRODUCT_MESSAGE_ACTIVE}all/`
export const PRODUCT_MESSAGE_ACTIVE_ALL_URL = `${API_URL}${PRODUCT_MESSAGE_ACTIVE_ALL}`
export const PRODUCT_MESSAGE_ALL = `${PRODUCT_MESSAGE}all/`
export const PRODUCT_MESSAGE_ALL_URL = `${API_URL}${PRODUCT_MESSAGE_ALL}`
export const PRODUCT_MESSAGE_ARCHIVE = `${PRODUCT_MESSAGE}archive/`
export const PRODUCT_MESSAGE_ARCHIVE_URL = `${API_URL}${PRODUCT_MESSAGE_ARCHIVE}`
export const PRODUCT_MESSAGE_ARCHIVED = `${PRODUCT_MESSAGE}archived/`
export const PRODUCT_MESSAGE_ARCHIVED_URL = `${API_URL}${PRODUCT_MESSAGE_ARCHIVED}`
export const PRODUCT_MESSAGE_ARCHIVED_ALL = `${PRODUCT_MESSAGE_ARCHIVED}all/`
export const PRODUCT_MESSAGE_ARCHIVED_ALL_URL = `${API_URL}${PRODUCT_MESSAGE_ARCHIVED_ALL}`
export const PRODUCT_MESSAGE_CREATE = `${PRODUCT_MESSAGE}create/`
export const PRODUCT_MESSAGE_CREATE_URL = `${API_URL}${PRODUCT_MESSAGE_CREATE}`
export const PRODUCT_MESSAGE_DELETE = `${PRODUCT_MESSAGE}delete/`
export const PRODUCT_MESSAGE_DELETE_URL = `${API_URL}${PRODUCT_MESSAGE_DELETE}`
export const PRODUCT_MESSAGE_DELETED = `${PRODUCT_MESSAGE}deleted/`
export const PRODUCT_MESSAGE_DELETED_URL = `${API_URL}${PRODUCT_MESSAGE_DELETED}`
export const PRODUCT_MESSAGE_DELETED_ALL = `${PRODUCT_MESSAGE_DELETED}all/`
export const PRODUCT_MESSAGE_DELETED_ALL_URL = `${API_URL}${PRODUCT_MESSAGE_DELETED_ALL}`
export const PRODUCT_MESSAGE_SEEN = `${PRODUCT_MESSAGE}seen/`
export const PRODUCT_MESSAGE_SEEN_URL = `${API_URL}${PRODUCT_MESSAGE_SEEN}`
export const PRODUCT_MESSAGE_UPDATE = `${PRODUCT_MESSAGE}update/`
export const PRODUCT_MESSAGE_UPDATE_URL = `${API_URL}${PRODUCT_MESSAGE_UPDATE}`

// Counter
export const COUNTER = 'counter/'
export const COUNTER_CLICK = `${COUNTER}click/`
export const COUNTER_CLICK_PHONE = `${COUNTER_CLICK}phone`
export const COUNTER_CLICK_PHONE_URL = `${API_URL}${COUNTER_CLICK_PHONE}`

// Store
export const STORE = 'store/'
export const STORE_URL = `${API_URL}${STORE}`
export const STORE_FILTER = `${STORE}filter/`
export const STORE_FILTER_URL = `${API_URL}${STORE_FILTER}`

// Web
export const WEB = 'web/'

// WebChat
export const WEB_CHAT = `${WEB}chat/`
export const WEB_CHAT_CHECK = `${WEB_CHAT}check/`
export const WEB_CHAT_CHECK_URL = `${API_URL}${WEB_CHAT_CHECK}`
export const WEB_CHAT_CREATE = `${WEB_CHAT}create/`
export const WEB_CHAT_CREATE_URL = `${API_URL}${WEB_CHAT_CREATE}`
export const WEB_CHAT_LIST = `${WEB_CHAT}list/`
export const WEB_CHAT_LIST_URL = `${API_URL}${WEB_CHAT_LIST}`
export const WEB_CHAT_MESSAGES = `${WEB_CHAT}messages/`
export const WEB_CHAT_MESSAGES_URL = `${API_URL}${WEB_CHAT_MESSAGES}`

// WebFavorites
export const WEB_FAVORITE = `${WEB}favorite/`
export const WEB_FAVORITE_PRODUCTS = `${WEB_FAVORITE}products/`
export const WEB_FAVORITE_PRODUCTS_URL = `${API_URL}${WEB_FAVORITE_PRODUCTS}`
export const WEB_FAVORITE_STORES = `${WEB_FAVORITE}stores/`
export const WEB_FAVORITE_STORES_URL = `${API_URL}${WEB_FAVORITE_STORES}`

// WebPage
export const WEB_PAGE = `${WEB}page/`
export const WEB_PAGE_PROMOTION = `${WEB_PAGE}promotion/`
export const WEB_PAGE_PROMOTION_PRODUCT = `${WEB_PAGE_PROMOTION}product/`
export const WEB_PAGE_PROMOTION_PRODUCT_INFO = `${WEB_PAGE_PROMOTION_PRODUCT}info/`
export const WEB_PAGE_PROMOTION_PRODUCT_INFO_URL = `${API_URL}${WEB_PAGE_PROMOTION_PRODUCT_INFO}`
export const WEB_PAGE_PROMOTION_STORE = `${WEB_PAGE_PROMOTION}store/`
export const WEB_PAGE_PROMOTION_STORE_INFO = `${WEB_PAGE_PROMOTION_STORE}info/`
export const WEB_PAGE_PROMOTION_STORE_INFO_URL = `${API_URL}${WEB_PAGE_PROMOTION_STORE_INFO}`
export const WEB_PAGE_SITE_MAP_INFO = `${WEB_PAGE}sitemap/info`
export const WEB_PAGE_SITE_MAP_INFO_URL = `${API_URL}${WEB_PAGE_SITE_MAP_INFO}`

// WebReview
export const WEB_REVIEW = `${WEB}review/`
export const WEB_REVIEW_URL = `${API_URL}${WEB_REVIEW}`

// WebStore
export const WEB_STORE = `${WEB}store/`
export const WEB_STORE_URL = `${API_URL}${WEB_STORE}`
export const WEB_STORE_ALL = `${WEB_STORE}all/`
export const WEB_STORE_ALL_COUNT = `${WEB_STORE_ALL}count/`
export const WEB_STORE_ALL_COUNT_URL = `${API_URL}${WEB_STORE_ALL_COUNT}`
export const WEB_STORE_ARCHIVE = `${WEB_STORE}archive/`
export const WEB_STORE_ARCHIVE_URL = `${API_URL}${WEB_STORE_ARCHIVE}`
export const WEB_STORE_EDIT = `${WEB_STORE}edit/`
export const WEB_STORE_EDIT_URL = `${API_URL}${WEB_STORE_EDIT}`
export const WEB_STORE_NAME = `${WEB_STORE}name/`
export const WEB_STORE_NAME_URL = `${API_URL}${WEB_STORE_NAME}`
export const WEB_STORE_NAME_CHECK = `${WEB_STORE_NAME}check/`
export const WEB_STORE_NAME_CHECK_URL = `${API_URL}${WEB_STORE_NAME_CHECK}`
export const WEB_STORE_OWN = `${WEB_STORE}own/`
export const WEB_STORE_OWN_ACTIVE = `${WEB_STORE_OWN}active/`
export const WEB_STORE_OWN_ACTIVE_URL = `${API_URL}${WEB_STORE_OWN_ACTIVE}`
export const WEB_STORE_OWN_INACTIVE = `${WEB_STORE_OWN}inactive/`
export const WEB_STORE_OWN_INACTIVE_URL = `${API_URL}${WEB_STORE_OWN_INACTIVE}`
export const WEB_STORE_UNARCHIVE = `${WEB_STORE}unarchive/`
export const WEB_STORE_UNARCHIVE_URL = `${API_URL}${WEB_STORE_UNARCHIVE}`

// Rubrics
export const RUBRIC = 'rubric/'
export const RUBRIC_URL = `${API_URL}${RUBRIC}`

// Category
export const CATEGORY = 'category/'
export const CATEGORY_URL = `${API_URL}${CATEGORY}`
export const CATEGORY_INFO = `${CATEGORY}info/`
export const CATEGORY_INFO_URL = `${API_URL}${CATEGORY_INFO}`

// Subcategory
export const SUBCATEGORY = 'subcategory/'
export const SUBCATEGORY_URL = `${API_URL}${SUBCATEGORY}`

// PageCategory
export const PAGE = 'page/'
export const PAGE_URL = `${API_URL}${PAGE}`
export const PAGE_CATEGORY = `${PAGE}category/web`
export const PAGE_CATEGORY_URL = `${API_URL}${PAGE_CATEGORY}`

// Authorization
export const SIGNIN = 'signin/'
export const SIGNIN_URL = `${API_URL}${SIGNIN}`
export const SIGNUP = 'signup/'
export const SIGNUP_URL = `${API_URL}${SIGNUP}`
export const SIGN_UP_PHONE = `${API_URL}mobile/auth/sms/send `

// Account
export const ACCOUNT = 'account/'
export const ACCOUNT_URL = `${API_URL}${ACCOUNT}`
export const ACCOUNT_ADVERT = `${ACCOUNT}product/`
export const ACCOUNT_ADVERT_URL = `${API_URL}${ACCOUNT_ADVERT}`
export const ACCOUNT_ADVERT_SUBSCRIBE = `${ACCOUNT_ADVERT}subscribe/`
export const ACCOUNT_ADVERT_SUBSCRIBE_URL = `${API_URL}${ACCOUNT_ADVERT_SUBSCRIBE}`
export const ACCOUNT_BALANCE = `${ACCOUNT}balance/`
export const ACCOUNT_BALANCE_URL = `${API_URL}${ACCOUNT_BALANCE}`
export const ACCOUNT_STORE = `${ACCOUNT}store/`
export const ACCOUNT_STORE_SUBSCRIBE = `${ACCOUNT_STORE}subscribe/`
export const ACCOUNT_STORE_SUBSCRIBE_URL = `${API_URL}${ACCOUNT_STORE_SUBSCRIBE}`

// Users
export const USER = 'user/'
export const USER_URL = `${API_URL}${USER}`
export const USER_ADMIN = `${USER}admin/`
export const USER_ADMIN_URL = `${API_URL}${USER_ADMIN}`
export const USER_SET = `${USER}set/`
export const USER_SET_URL = `${API_URL}${USER_SET}`
export const USER_SET_LOCATION = `${USER_SET}location/`
export const USER_SET_LOCATION_URL = `${API_URL}${USER_SET_LOCATION}`
export const USER_USER = `${USER}user/`
export const USER_USER_URL = `${API_URL}${USER_USER}`
export const USER_ME = `${USER}me/`
export const USER_ME_URL = `${API_URL}${USER_ME}`
export const USER_ME_INFO = `${USER_ME}info/`
export const USER_ME_INFO_URL = `${API_URL}${USER_ME_INFO}`
export const USER_PROFILE = 'profile/'
export const USER_PROFILE_URL = `${API_URL}${USER_PROFILE}`
export const USER_PROFILE_UPDATE = `${USER_PROFILE}update/`
export const USER_PROFILE_REMOVE_PHOTO = `${USER_PROFILE}remove/photo`
export const USER_PROFILE_UPDATE_URL = `${API_URL}${USER_PROFILE_UPDATE}`
export const USER_PROFILE_REMOVE_PHOTO_URL = `${API_URL}${USER_PROFILE_REMOVE_PHOTO}`
export const USER_PRODUCTS = `${USER}products/`
export const USER_PRODUCTS_URL = `${API_URL}${USER_PRODUCTS}`
export const USER_PRODUCTS_ALL = `${USER_PRODUCTS}all/`
export const USER_PRODUCTS_ALL_URL = `${API_URL}${USER_PRODUCTS_ALL}`
export const USER_PRODUCTS_ALL_ACTIVE = `${USER_PRODUCTS_ALL}active/`
export const USER_PRODUCTS_ALL_ACTIVE_URL = `${API_URL}${USER_PRODUCTS_ALL_ACTIVE}`
export const USER_PRODUCTS_ALL_COUNT = `${USER_PRODUCTS_ALL}count/`
export const USER_PRODUCTS_ALL_COUNT_URL = `${API_URL}${USER_PRODUCTS_ALL_COUNT}`
export const USER_PRODUCTS_ALL_DELETED = `${USER_PRODUCTS_ALL}deleted`
export const USER_PRODUCTS_ALL_DELETED_URL = `${API_URL}${USER_PRODUCTS_ALL_DELETED}`

// Profile
export const PROFILE = 'profile/'
export const PROFILE_UPDATE = `${PROFILE}update/`
export const PROFILE_UPDATE_URL = `${API_URL}${PROFILE_UPDATE}`

// Settings
export const SETTINGS = 'settings/'
export const SETTINGS_UPDATE = `${SETTINGS}update/`
export const SETTINGS_UPDATE_URL = `${API_URL}${SETTINGS_UPDATE}`

// Main Info
export const MAIN_INFO = 'web/settings/main/info'
export const MAIN_INFO_URL = `${API_URL}${MAIN_INFO}`

// Payment
export const PAYMENT = 'payment/'
export const PAYMENT_GENERATE = `${PAYMENT}generate/`
export const PAYMENT_GENERATE_URL = `${PAYMENT_GENERATE}url/`
export const PAYMENT_GENERATE_URL_URL = `${API_URL}${PAYMENT_GENERATE_URL}`

// Language
export const LANGUAGE = 'language/'
export const LANGUAGE_URL = `${API_URL}${LANGUAGE}`
export const LANGUAGE_UPDATE = `${LANGUAGE}update/`
export const LANGUAGE_UPDATE_URL = `${API_URL}${LANGUAGE_UPDATE}`

// Country
export const COUNTRY = 'country/'
export const COUNTRY_URL = `${API_URL}${COUNTRY}`

// Region
export const REGION = 'region/'
export const REGION_URL = `${API_URL}${REGION}`

// City
export const CITY = 'city/'
export const CITY_URL = `${API_URL}${CITY}`
export const CITY_ALL = `${CITY}all/`
export const CITY_ALL_URL = `${API_URL}${CITY_ALL}`
export const CITY_ALL_SHORT = `${CITY_ALL}short/`
export const CITY_ALL_SHORT_URL = `${API_URL}${CITY_ALL_SHORT}`

// Cities
export const CITIES = 'web/cities/'
export const CITIES_ALL_SHORT = `${CITIES}all/short/`
export const CITIES_ALL_SHORT_URL = `${API_URL}${CITIES_ALL_SHORT}`
export const CITIES_SEARCH = `${CITIES}search/`
export const CITIES_SEARCH_URL = `${API_URL}${CITIES_SEARCH}`
export const CITIES_REGIONS = `${CITIES}regions/`
export const CITIES_REGIONS_URL = `${API_URL}${CITIES_REGIONS}`

// District
export const DISTRICT = 'district/'
export const DISTRICT_URL = `${API_URL}${DISTRICT}`

// Attachment
// export const FILE = 'file/'
// export const FILE_UPLOAD_REACT = `${FILE}react/upload/`
// export const FILE_UPLOAD_SINGLE = `${FILE_UPLOAD_REACT}single`
// export const FILE_UPLOAD_MULTIPLE = `${FILE_UPLOAD_REACT}multiple`
// export const FILE_UPLOAD_REACT_SINGLE_URL = `${API_URL}${FILE_UPLOAD_SINGLE}`
// export const FILE_UPLOAD_REACT_MULTIPLE_URL = `${API_URL}${FILE_UPLOAD_MULTIPLE}`

// New Attachment
export const NEW_FILE_UPLOAD_REACT = 'media/upload/react'
export const NEW_FILE_UPLOAD_REACT_URL = `${API_URL}${NEW_FILE_UPLOAD_REACT}`
